import { GET_DOWNTIME_REASONS_QUERY_KEY } from "@/api/downtimeReasons/useDowntimeReasonsGetQuery";
import DefaultInstance from "@/instance/axios";
import {
  DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest,
  DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest,
  DefaultApiDowntimeReasonsPostRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

interface CreateParams extends DefaultApiDowntimeReasonsPostRequest { }
interface UpdateParams
  extends DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest { }
interface DeleteParams
  extends DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest { }

type MutationType = "create" | "update" | "delete";

type Params<T extends MutationType> = T extends "create"
  ? CreateParams
  : T extends "update"
  ? UpdateParams
  : DeleteParams;

const createFetcher = (params: CreateParams) =>
  DefaultInstance.downtimeReasonsPost({
    downtimeReasonsGetRequest: params.downtimeReasonsGetRequest,
  });

const updateFetcher = (params: UpdateParams) =>
  DefaultInstance.downtimeReasonsDowntimeReasonCodePut(params);

const deleteFetcher = (params: DeleteParams) =>
  DefaultInstance.downtimeReasonsDowntimeReasonCodeDelete(params);

const useDowntimeReasonsMutationQuery = <T extends MutationType>(type: T) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (params: Params<typeof type>) => createFetcher(params as CreateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DOWNTIME_REASONS_QUERY_KEY);
      },
    }
  );

  const updateMutation = useMutation(
    (params: Params<typeof type>) => updateFetcher(params as UpdateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DOWNTIME_REASONS_QUERY_KEY);
      },
    }
  );

  const deleteMutation = useMutation(
    (params: Params<typeof type>) => deleteFetcher(params as DeleteParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DOWNTIME_REASONS_QUERY_KEY);
      },
    }
  );

  switch (type) {
    case "create":
      return createMutation;
    case "update":
      return updateMutation;
    case "delete":
      return deleteMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useDowntimeReasonsMutationQuery;
