import { WHO_AM_I_KEY } from "@/api/useAuthWhoamiGet";
import { MasterInstance } from "@/instance/axios";
import {
  MasterApiUsersUserIdPutRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export const GET_USERS_QUERY_KEY = "users";

interface UpdateParams extends MasterApiUsersUserIdPutRequest { }

type MutationType = "update";

type Params<T extends MutationType> = T extends "update" ? UpdateParams : 'error';


const updateFetcher = (params: UpdateParams) =>
  MasterInstance.usersUserIdPut(params);

const useUsersMutationQuery = <T extends MutationType>(type: T) => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (params: Params<typeof type>) => updateFetcher(params as UpdateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USERS_QUERY_KEY);
        queryClient.invalidateQueries(WHO_AM_I_KEY);
      },
    }
  );

  if (type === "update") {
    return updateMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useUsersMutationQuery;
