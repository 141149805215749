import { Flex, Loader } from "@mantine/core";
import { QueryFunction, useQuery, UseQueryOptions } from "@tanstack/react-query";
import React, { ReactNode } from "react";

interface UseFetcherResult<TData> {
    data: TData | undefined;
    Fetcher: React.FC<{ children: ReactNode }>;
}

function useFetcher<
    TData,
    TError,
    TQueryKey extends readonly unknown[]
>(
    queryOptions: {
        queryKey: TQueryKey;
        queryFn: QueryFunction<TData, TQueryKey>;
    },
    options?: UseQueryOptions<TData, TError, TData, TQueryKey>
): UseFetcherResult<TData> {

    const queryResult = useQuery<TData, TError, TData, TQueryKey>({
        queryKey: queryOptions.queryKey,
        queryFn: queryOptions.queryFn,
        ...options,
    });

    const Fetcher: React.FC<{ children: ReactNode }> = ({ children }) => {
        if (queryResult.isLoading) {
            return <Flex w="100%" h="100%" justify="center" align="center"><Loader /></Flex>
        }
        return <>{children}</>;
    };

    return {
        data: queryResult.data,
        Fetcher,
    };
}

export default useFetcher;
