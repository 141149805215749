import { ProductionInstance } from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import { ProductionActionApiEquipmentsCorrectionPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const EQUIPMENT_CORRECTION_API = "EquipmentCorrectionApi";

export const equipmentCorrection = createMutationKeys(EQUIPMENT_CORRECTION_API, {
    put: (params: ProductionActionApiEquipmentsCorrectionPutRequest) => ({
        mutationKey: ['putCorrection', params],
        mutationFn: () => ProductionInstance.equipmentsCorrectionPut(params)
    })
});

