import { useModal } from "@/context/ModalStackManager";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Box, Button, Group, Radio, Title } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum, ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface SetLogTypeFormProps {
  activeType:
  | ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum
  | undefined;
  onSubmit?: (values: any) => void;
  defaultValue?: string;
}

export function EndSetForm(params: SetLogTypeFormProps) {
  const { closeModal } = useModal();
  const { onSubmit, defaultValue } = params;
  const [value, setValue] = useState(defaultValue);

  const onSubmitForm = (e: any) => {
    onSubmit && onSubmit(value);
    closeModal({});
  };
  const { t } = useTranslation();

  return (
    <Box
      style={{
        width: "70rem",
        height: "35rem",
      }}
    >
      <FormHeader>
        <Title mb={"1em"} size="2em">
          {t("실적 입력 방식 설정")}
        </Title>
      </FormHeader>
      <FormBody>
        <Radio.Group value={value} onChange={setValue} h="100%">
          <Group
            mt="xs"
            spacing={"3rem"}
            h="100%"
            style={{ flexDirection: "column" }}
          >
            <Radio
              disabled
              styles={{
                label: { fontSize: "1.8em" },
                description: { fontSize: "1.2rem" },
              }}
              w="100%"
              ml="2rem"
              value={ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_AUTO_REPORT}
              label={t("카운터 연동 + 자동 보고")}
              description={
                t("실적 수량을 수기로 입력할 수 없습니다. 카운터 값이 변경되었을 때마다 자동 보고됩니다.")
              }
            />
            <Radio
              styles={{
                label: { fontSize: "1.8em" },
                description: { fontSize: "1.2rem" },
              }}
              w="100%"
              ml="2rem"
              value={ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.HANDWRITING}
              label={t("수기 입력 사용")}
              description={
                t("실적 수량을 수기로 입력할 수 있습니다. 임시저장이 필요합니다.")
              }
            />
            <Radio
              styles={{
                label: { fontSize: "1.8em" },
                description: { fontSize: "1.2rem" },
              }}
              w="100%"
              ml="2rem"
              value={ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_HANDWRITING}
              label={t("연동 + 수기 입력 사용")}
              description={
                t("실적 수량이 카운터를 기준으로 입력되지만, 임시저장이 필요합니다.")
              }
            />
          </Group>
        </Radio.Group>
      </FormBody>
      <FormFooter>
        <SaveBtn fz="xl" size="lg" onClick={onSubmitForm}>
          {t("저장")}
        </SaveBtn>
      </FormFooter>
    </Box>
  );
}

const FormHeader = styled.header`
  height: 15%;
`;
const FormBody = styled.body`
  width: 100%;
  height: 75%;
  background-color: inherit;
`;
const FormFooter = styled.footer`
  height: 10%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const SaveBtn = styled<any>(Button)`
  width: 100%;
  border-radius: 4px;
  color: var(--blue-6, #228be6);
  border: 1px dashed var(--blue-6, #228be6);
  background: rgba(34, 139, 230, 0.03);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  &:hover {
    background: ${theme.colors?.blue?.[1]};
  }
`;
