import { useLocalStorage } from "@/context/LocalStorageProvider";
import Layout from "@/layouts/Layout";
import Auth from "@/pages/auth";
import { Inspections } from "@/pages/inspections";
import { Inventory } from "@/pages/inventory";
import { WarningPopPageInfo } from "@/pages/warning/warningPopPageInfo";
import { WorkByRowPage } from "@/pages/workByRow";
import { Works } from "@/pages/works";

import { useCheckAuth } from "@/utils/checkAuth";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

function ProtectedRoute({
  redirectCondition,
  redirectPath,
  children,
}: {
  redirectCondition: boolean;
  redirectPath: string;
  children: React.ReactNode;
}) {
  return redirectCondition ? <Navigate to={redirectPath} /> : <>{children}</>;
}

const routes = [
  {
    path: "/works",
    element: <Works />,
    protected: true,
  },
  {
    path: "/work/:workId",
    element: (workId: number) =>
      workId ? <WorkByRowPage /> : <WarningPopPageInfo />,
    protected: true,
  },
  {
    path: "/inventory",
    element: <Inventory />,
    protected: true,
  },
  {
    path: "/inspections",
    element: <Inspections />,
    protected: true,
  },
];

function Index() {
  const { workId } = useLocalStorage();
  const auth = useCheckAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="App">
            <div className="container">
              <div className="unauth-container">
                <Outlet />
              </div>
            </div>
          </div>
        }
      >
        <Route path="/" element={auth ? <Navigate to="/works" /> : <Auth />} />
      </Route>
      <Route path="/" element={<Layout />}>
        {routes.map(({ path, element, protected: isProtected }, index) => (
          <Route
            key={index}
            path={path}
            element={
              isProtected ? (
                <ProtectedRoute redirectCondition={!auth} redirectPath="/">
                  {typeof element === "function" ? element(workId) : element}
                </ProtectedRoute>
              ) : typeof element === "function" ? (
                element(workId)
              ) : (
                element
              )
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default Index;
