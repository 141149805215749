import { users } from "@/api/user/useUserQuery";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import useFetcher from "@/test/hooks/useFetcher";

export const useUserFormFetch = () => {
  const {} = useLocalStorage();

  const { queryKey, queryFn } = users.getSelect({});

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
