import React, { createContext, useContext, useEffect, useState } from "react";

// LocalStorageContext를 생성합니다.
const LocalStorageContext = createContext<{
  workId?: any;
  routingCode?: any;
  userId?: any;
  userName?: any;
  name?: any;
  equipmentCode?: any;
  equipmentName?: any;
  managementRole?: any;
  locationCode?: any;
  printCode?: any;
  tempWork?: any;
  isAuthenticated?: string;
  setWorkId: (value: any) => void;
  setUserId: (value: any) => void;
  setUserName: (value: any) => void;
  setName: (value: any) => void;
  setTempWork: (value: any) => void;
  setEquipmentCode: (value: any) => void;
  setEquipmentName: (value: any) => void;
  setRoutingCode: (value: any) => void;
  setManagementRole: (value: any) => void;
  setLocationCode: (value: any) => void;
  setPrintCode: (value: any) => void;
  setIsAuthenticated: (value: boolean) => void;
}>({
  workId: null,
  routingCode: null,
  userId: null,
  userName: null,
  name: null,
  equipmentCode: null,
  equipmentName: null,
  managementRole: null,
  locationCode: null,
  printCode: null,
  tempWork: null,
  setWorkId: () => {},
  setUserId: () => {},
  setUserName: () => {},
  setName: () => {},
  setTempWork: () => {},
  setEquipmentCode: () => {},
  setEquipmentName: () => {},
  setRoutingCode: () => {},
  setManagementRole: () => {},
  setLocationCode: () => {},
  setPrintCode: () => {},
  setIsAuthenticated: () => {},
});

// LocalStorageProvider 컴포넌트를 정의합니다.
export const LocalStorageProvider: React.FC<{
  initialId?: any;
  initialRoutingCode?: any;
  initialUserId?: any;
  initialUserName?: any;
  initialName?: any;
  initialEquipmentCode?: any;
  initialEquipmentName?: any;
  initialManagementRole?: any;
  initialLocationCode?: any;
  initialPrintCode?: any;
  initAuthenticated?: any;
  children?: any;
}> = ({
  initialId,
  initialRoutingCode,
  initialUserId,
  initialUserName,
  initialName,
  initialEquipmentCode,
  initialEquipmentName,
  children,
  initialManagementRole,
  initialLocationCode,
  initialPrintCode,
  initAuthenticated = false,
}: any) => {
  const [equipmentCode, setEquipmentCode] = useState(() => {
    try {
      const item = window.localStorage.getItem("equipmentCode");
      return item ? item : initialEquipmentCode;
    } catch (error) {
      return initialEquipmentCode;
    }
  });

  const [equipmentName, setEquipmentName] = useState(() => {
    try {
      const item = window.localStorage.getItem("equipmentName");
      return item ? item : initialEquipmentName;
    } catch (error) {
      return initialEquipmentName;
    }
  });
  const [userId, setUserId] = useState(() => {
    try {
      const item = window.localStorage.getItem("userId");
      return item ? item : initialUserId;
    } catch (error) {
      return initialUserId;
    }
  });

  const [userName, setUserName] = useState(() => {
    try {
      const item = window.localStorage.getItem("userName");
      return item ? item : initialUserName;
    } catch (error) {
      return initialUserName;
    }
  });

  const [name, setName] = useState(() => {
    try {
      const item = window.localStorage.getItem("name");
      return item ? item : initialName;
    } catch (error) {
      return initialName;
    }
  });
  const [workId, setWorkId] = useState(() => {
    try {
      const item = window.localStorage.getItem("workId");
      return item ? item : initialId;
    } catch (error) {
      return initialId;
    }
  });

  const [routingCode, setRoutingCode] = useState(() => {
    try {
      const item = window.localStorage.getItem("routingCode");
      return item ? item : initialRoutingCode;
    } catch (error) {
      return initialRoutingCode;
    }
  });

  const [managementRole, setManagementRole] = useState(() => {
    try {
      const item = window.localStorage.getItem("managementRole");
      return item ? item : initialManagementRole;
    } catch (error) {
      return initialManagementRole;
    }
  });

  const [locationCode, setLocationCode] = useState(() => {
    try {
      const item = window.localStorage.getItem("locationCode");
      return item ? item : initialLocationCode;
    } catch (error) {
      return initialLocationCode;
    }
  });

  const [printCode, setPrintCode] = useState(() => {
    try {
      const item = window.localStorage.getItem("printCode");
      return item ? item : initialPrintCode;
    } catch (error) {
      return initialPrintCode;
    }
  });

  const [tempWork, setTempWork] = useState(() => {
    try {
      const item = window.localStorage.getItem(`tempWork_${workId}`);
      return item
        ? JSON.parse(item)
        : {
            defects: undefined,
            end: undefined,
          };
    } catch (error) {
      return {
        defects: undefined,
        end: undefined,
      };
    }
  });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    try {
      const item = window.localStorage.getItem("isAuthenticated");
      return item ? item : initAuthenticated;
    } catch (error) {
      return initAuthenticated;
    }
  });

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(`tempWork_${workId}`);
      const newTempWork = item
        ? JSON.parse(item)
        : {
            defects: undefined,
            end: undefined,
          };
      setTempWork(newTempWork);
    } catch (error) {
      setTempWork({
        defects: undefined,
        end: undefined,
      });
    }
  }, [workId]);

  useEffect(() => {
    if (tempWork !== undefined) {
      window.localStorage.setItem(
        `tempWork_${workId}`,
        JSON.stringify(tempWork)
      );
    }
  }, [tempWork]);

  useEffect(() => {
    window.localStorage.setItem("workId", workId);
    window.localStorage.setItem("equipmentCode", equipmentCode);
    window.localStorage.setItem("equipmentName", equipmentName);
    window.localStorage.setItem("routingCode", routingCode);
    window.localStorage.setItem("userId", userId);
    window.localStorage.setItem("userName", userName);
    window.localStorage.setItem("managementRole", managementRole);
    window.localStorage.setItem("name", name);
    window.localStorage.setItem("locationCode", locationCode);
    window.localStorage.setItem("printCode", printCode);
    window.localStorage.setItem("isAuthenticated", isAuthenticated);
  }, [
    workId,
    routingCode,
    userId,
    userName,
    equipmentCode,
    equipmentName,
    managementRole,
    name,
    locationCode,
    printCode,
    isAuthenticated,
  ]);

  return (
    <LocalStorageContext.Provider
      value={{
        workId,
        routingCode,
        equipmentCode,
        equipmentName,
        userId,
        userName,
        managementRole,
        name,
        tempWork,
        isAuthenticated,
        setName,
        setWorkId,
        setRoutingCode,
        setUserId,
        setUserName,
        setEquipmentCode,
        setManagementRole,
        setTempWork,
        locationCode,
        setLocationCode,
        printCode,
        setPrintCode,
        setEquipmentName,
        setIsAuthenticated,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

// useLocalStorage 훅을 정의합니다.
export const useLocalStorage = () => {
  return useContext(LocalStorageContext);
};
