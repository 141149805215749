import { customFunctions } from "@/config/customFunctions";
import { useWorkDataStore } from "@/store/work.store";
import ExpandableAccordion from "@/test/components/atom/accordion/expandable/ExpandableAccordion";
import { WorkTitle } from "@/test/components/atom/title/work/WorkTitle";
import { MantineDataTable } from "@/test/components/molecule/mantineDataTable/MantineDataTable";
import { COMMON_TEXT } from "@/test/constants/text";
import { Accordion } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

const WorkCurrentDefect = Object.assign(
  {},
  {
    Title: WorkTitle,
    Table: MantineDataTable,
    Accordion: ExpandableAccordion,
  }
);

export type DefectObject = {
  [key: string]: DefectObjectProps;
};

export interface DefectObjectProps {
  defectQuantity: string;
  reworkQuantity?: string;
  actualDefectQuantity?: string;
  defectName?: string;
  defectCode?: string;
}

export const WorkCurrentDefectInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { work } = workData;

  const getCurrentDefectList = (
    work: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
  ) => {
    const defect = work?.currentLotSummary?.defect as DefectObject | undefined;
    if (!defect) return [];

    return Object.keys(defect).map((code) => ({
      defectName: defect[code]?.defectName ?? "",
      defectCode: code,
      defectQuantity: defect[code]?.defectQuantity ?? "",
      setUnitText: work?.item?.unitText ?? "",
    }));
  };

  const filterAndConvertDefectList = (
    defectList: {
      defectName: string;
      defectCode: string;
      defectQuantity: string;
      setUnitText: string;
    }[]
  ) => {
    return defectList
      .filter((defect) => defect.defectQuantity !== "0")
      .map(({ defectName, defectQuantity, setUnitText }) => ({
        defectName,
        defectQuantity: `${defectQuantity}${setUnitText}`,
      }));
  };

  // 데이터 처리
  const currentDefectList = getCurrentDefectList(work);
  const convertedDefectList = filterAndConvertDefectList(currentDefectList);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "defectName",
        header: COMMON_TEXT.COLUMN.DEFECT_NAME,
      },
      {
        accessorKey: "defectQuantity",
        header: COMMON_TEXT.COLUMN.DEFECT_QUANTITY,
      },
    ],
    []
  );

  return (
    <WorkCurrentDefect.Accordion>
      <Accordion.Item value="currentDefectTable">
        <Accordion.Control>
          <WorkCurrentDefect.Title fz={"1.625rem"} c={"#FFFFFF"}>
            {customFunctions.ADD_TEMP_FUNCTION_ENABLED
              ? COMMON_TEXT.TITLE.CURRENT_LOT_DEFECT
              : COMMON_TEXT.TITLE.CURRENT_DEFECT}
          </WorkCurrentDefect.Title>
        </Accordion.Control>
        <Accordion.Panel>
          <WorkCurrentDefect.Table
            columns={columns}
            data={convertedDefectList ?? []}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </WorkCurrentDefect.Accordion>
  );
};
