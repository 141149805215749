import { BaseNumberInput } from "@/test/components/atom/numberInput/BaseNumberInput"
import { NumberInputProps } from "@mantine/core"


interface FormNumberInputProps extends NumberInputProps { }

export const FormNumberInput = ({ ...etcProps }: FormNumberInputProps) => {

    return (
        <BaseNumberInput
            size="lg"
            {...etcProps}
        />
    )

}