import { BaseButton } from '@/components/base/button/BaseButton';
import { useModal } from '@/context/ModalStackManager';
import { Flex, Text } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmProps {
    message: string | JSX.Element;
}

export const Confirm: React.FC<ConfirmProps> = ({ message }) => {

    const { t } = useTranslation();
    const { closeModal } = useModal();

    const handleSubmit = () => {
        closeModal(true);  // 입력 값을 모달 결과로 전달
    };

    const handleClose = () => {
        closeModal(false);  // 모달을 닫음
    }

    return (
        <Flex w="60rem" mih="10rem" direction="column" gap="sm" justify="space-between">
            <Text style={{ wordWrap: "break-word", overflowWrap: 'break-word', whiteSpace: "pre-wrap" }} size="3rem">{message}</Text>
            <Flex gap="md" justify="flex-end">
                <BaseButton color="red" size='xl' onClick={handleClose}>
                    {t("아니오")}
                </BaseButton>
                <BaseButton color="green" size='xl' onClick={handleSubmit}>
                    {t("예")}
                </BaseButton>
            </Flex>
        </Flex>
    );
};
