import { mutateTransform } from "@/api/transform/useTransformQuery";
import { InventoriesAutoComplete } from "@/components/autoComplete/inventory/inventories-autoComplete";
import { ItemAutoComplete } from "@/components/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/components/autoComplete/location/location-autoComplate";
import { FormButtonBox } from "@/components/form/FormButtonBox";
import { FormMain } from "@/components/form/FormMain";
import { useModal } from "@/context/ModalStackManager";
import { useWorkDataStore } from "@/store/work.store";
import { customNotification } from "@/utils/notificationShow";
import { Box, Button, Divider, Flex, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiWmsTransformPostRequest, WmsTransformPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

const Create = Object.assign({}, FormMain, {
    Title: Title,
    Button: Button,
    Input: TextInput,
    ButtonBox: FormButtonBox,
    LocationAutoComplete: LocationAutoComplete,
    InventoriesAutoComplete: InventoriesAutoComplete,
    ItemAutoComplete: ItemAutoComplete,
});


export const RecycleForm = () => {

    const { closeModal } = useModal();
    const { t } = useTranslation();
    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { work } = workData

    const { mutate: transformMutate } = useMutation(
        (params: DefaultApiWmsTransformPostRequest) =>
            mutateTransform.transform(params).mutationFn(undefined),
        {
            onSuccess: () => {
                customNotification.success({ "message": t("변환되었습니다.") })
                closeModal({})
            },
            onError: (error: AxiosError<Error>) => {
                if (error?.response?.data.message === undefined) return;
                customNotification.error({
                    message: error?.response?.data.message
                });
            }
        }
    );

    const form = useForm<WmsTransformPostRequest>({
        initialValues: {
            sourceItemCode: work?.item?.code as string,
            sourceLocationCode: work?.defectLocationCode as string,
            sourceLotId: 0,
            sourceQuantity: '',
            targetItemCode: '',
            targetLocationCode: '',
            targetLotId: 0,
            targetQuantity: '',
        },
        validate: {

            sourceLocationCode: (value) => !value ? t('창고를 선택해주세요') : undefined,
            sourceLotId: (value) => !value ? t('재고를 선택해주세요') : undefined,
            sourceQuantity: (value) => !value ? t('수량을 입력해주세요') : undefined,

            targetItemCode: (value) => !value ? t('품목을 선택해주세요') : undefined,
            targetLocationCode: (value) => !value ? t('창고를 선택해주세요') : undefined,
            targetLotId: (value) => !value ? t('재고를 선택해주세요') : undefined,
            targetQuantity: (value) => !value ? t('수량을 입력해주세요') : undefined,
        }
    })

    const onSubmit = (values: WmsTransformPostRequest) => {
        transformMutate({
            wmsTransformPostRequest: {
                ...values,
                sourceLotId: Number(values.sourceLotId),
                targetLotId: Number(values.targetLotId),
            }
        })
    }

    return (
        <Box>
            <form
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "30rem",
                }}
                onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}
            >
                <Flex gap={10} direction={'column'}>

                    <Create.Title order={3}>
                        {t('재활용 할 불량')}
                    </Create.Title>
                    <Create.LocationAutoComplete
                        key="sourceLocationCode"
                        disabled
                        label={t("불량 창고")}
                        query={{ $and: [{ isAvailable: false }] }}
                        {...form.getInputProps('sourceLocationCode')}
                    />
                    <Create.InventoriesAutoComplete
                        key="sourceLotId"
                        label={t("재고")}
                        query={{
                            itemCode: work?.item?.code,
                            locationCode: work?.defectLocationCode,
                        }}
                        setSelectedOption={(data) => {
                            form.setFieldValue('sourceQuantity', data?.quantity as string);
                        }}
                        {...form.getInputProps('sourceLotId')}
                        onChange={(e: any) => {
                            // 이동 창고 삭제시 재고, 품목, 수량 초기화
                            if (e === null) {
                                form.setFieldValue('sourceQuantity', '');
                                form.setFieldValue('sourceLotId', 0);
                            } else {
                                form.setFieldValue('sourceLotId', e);
                            }
                        }}
                    />
                    <Create.Input
                        key="sourceQuantity"
                        label={t("수량")}
                        {...form.getInputProps('sourceQuantity')}
                    />

                    <Divider mt={15} mb={15} />

                    <Create.Title order={3}>
                        {t('재활용 될 불량')}
                    </Create.Title>

                    <Create.ItemAutoComplete
                        key="targetItemCode"
                        label={t("품목")}
                        {...form.getInputProps('targetItemCode')}
                        onChange={(e: string | null) => {
                            // 품목을 삭제할시 재고, 창고, 수량 초기화
                            if (e === null) {
                                form.setFieldValue('targetLocationCode', '');
                                form.setFieldValue('targetLotId', 0);
                                form.setFieldValue('targetQuantity', '');
                                form.setFieldValue('targetItemCode', '');
                            } else {
                                form.setFieldValue('targetItemCode', e);
                            }

                        }}
                    />
                    <Create.LocationAutoComplete
                        key={`targetLocationCode + ${form.values.targetItemCode}`}
                        disabled={!form.values.targetItemCode}
                        label={t("이동 창고")}
                        {...form.getInputProps('targetLocationCode')}
                        onChange={(e: string | null) => {
                            // 이동 창고 삭제시 재고, 품목, 수량 초기화
                            if (e === null) {
                                form.setFieldValue('targetLotId', 0);
                                form.setFieldValue('targetQuantity', '');
                                form.setFieldValue('targetLocationCode', '');
                            } else {
                                form.setFieldValue('targetLocationCode', e);
                            }
                        }}
                    />
                    <Create.InventoriesAutoComplete
                        key={`targetLotId + ${form.values.targetItemCode} + ${form.values.targetLocationCode}`}
                        disabled={!form.values.targetItemCode || !form.values.targetLocationCode}
                        label={t("재고")}
                        itemCode={form.values.targetItemCode}
                        locationCode={form.values.targetLocationCode}
                        query={{
                            itemCode: form.values.targetItemCode
                        }}
                        {...form.getInputProps('targetLotId')}
                        onChange={(e: any) => {
                            // 이동 창고 삭제시 재고, 품목, 수량 초기화
                            if (e === null) {
                                form.setFieldValue('targetQuantity', '');
                                form.setFieldValue('targetLotId', 0);
                            } else {
                                form.setFieldValue('targetLotId', e);
                            }
                        }}
                    />
                    <Create.Input
                        key="targetQuantity"
                        label={t("수량")}
                        {...form.getInputProps('targetQuantity')}
                    />
                </Flex>
                <Create.ButtonBox>
                    <Create.Button
                        type="submit"
                    >
                        {t('변환')}
                    </Create.Button>
                </Create.ButtonBox>
            </form>
        </Box>
    );
};
