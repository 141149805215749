import { CancelButton } from '@/test/components/atom/button/cancel/CancelButton';
import { SaveButton } from '@/test/components/atom/button/save/SaveButton';
import { ButtonProps, Group } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';


interface FormActionButtonsProps<T> extends ButtonProps {
    form?: UseFormReturnType<T>;
    onSubmit?: (values?: T) => void;
    onCancel?: () => void;
    label?: string;
}

export const FormActionButtonGroup = <T,>({
    form,
    onSubmit,
    onCancel,
    label = '확인',
    ...etcProps
}: FormActionButtonsProps<T>) => {
    return (
        <Group position="right" spacing="sm">
            {
                onCancel && <CancelButton onCancel={onCancel} {...etcProps}>{'취소'}</CancelButton>
            }
            <SaveButton form={form} onSubmit={onSubmit} {...etcProps}>{label}</SaveButton>
        </Group>
    );
};
