import { BaseButton } from "@/components/base/button/BaseButton";
import { customFunctions } from "@/config/customFunctions";
import useConfirmModal from "@/hooks/useConfirmModal.hook";
import useWorkActions from "@/hooks/useWorkActions.hook";
import { useWorkDataStore } from "@/store/work.store";
import ExpandableAccordion from "@/test/components/atom/accordion/expandable/ExpandableAccordion";
import { OutsourceInformBox } from "@/test/components/atom/box/outsource/OutSourceInformBox";
import StatusProgressBar from "@/test/components/atom/progress/status/StatusProgressBar";
import { WorkText } from "@/test/components/atom/text/work/WorkText";
import { WorkTitle } from "@/test/components/atom/title/work/WorkTitle";
import { MantineDataTable } from "@/test/components/molecule/mantineDataTable/MantineDataTable";
import { COMMON_TEXT } from "@/test/constants/text";
import { setToLocaleString } from "@/utils/unitMark";
import { Accordion, Flex } from "@mantine/core";
import { IconRefreshDot } from "@tabler/icons-react";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

const WorkInventories = Object.assign(
  {},
  {
    Box: OutsourceInformBox,
    Text: WorkText,
    Title: WorkTitle,
    Button: BaseButton,
    Table: MantineDataTable,
    Progress: StatusProgressBar,
    Accordion: ExpandableAccordion,
  }
);

export const WorkInventoryInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work, outsource, inventories } = workData;
  const { confirmFlushModal } = useConfirmModal();
  const { onFlush } = useWorkActions({ workData: work });

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "itemName",
        header: COMMON_TEXT.COLUMN.ITEM_NAME,
      },
      {
        accessorKey: "gradeName",
        header: COMMON_TEXT.COLUMN.GRADE,
      },
      {
        accessorKey: "itemCategoryName",
        header: COMMON_TEXT.COLUMN.COLOR,
      },
      {
        accessorKey: "abstractItemCode",
        header: COMMON_TEXT.COLUMN.SHAPE,
      },
      {
        accessorKey: "remainQuantity",
        header: COMMON_TEXT.COLUMN.REMAIN_QUANTITY,
      },
    ],
    []
  );

  const convertedInventoryData = inventories?.rows?.map((row) => {
    return {
      itemName: row.itemName,
      gradeName: row.grade?.name,
      itemCategoryName: row.itemCategory?.name,
      abstractItemCode: row.abstractItem?.code,
      remainQuantity:
        setToLocaleString(row.quantity ?? "") + " " + row.unitText,
    };
  });

  return (
    <WorkInventories.Accordion>
      <Accordion.Item value="ledgerTable">
        <Accordion.Control>
          <Flex w={"100%"} justify={"space-between"}>
            <WorkInventories.Title fz={"1.625rem"} c={"#FFFFFF"}>
              {COMMON_TEXT.TITLE.PRODUCTABLE_INVENTORY_TITLE}
            </WorkInventories.Title>
            {work?.routingOutsourceId && (
              <WorkInventories.Box>{`${COMMON_TEXT.TITLE.OUTSOURCE_TITLE} : ${outsource?.name}`}</WorkInventories.Box>
            )}
            {customFunctions.ADD_FLUSHING_BUTTON && (
              <WorkInventories.Button
                color="gray.5"
                size="md"
                onClick={async () => {
                  if ((inventories?.rows?.length as number) > 0) {
                    const confirmFlush = await confirmFlushModal({
                      workInventoriesData: inventories,
                    });
                    if (confirmFlush) {
                      onFlush();
                    }
                  }
                }}
                rightIcon={<IconRefreshDot size={"2rem"} />}
              >
                {COMMON_TEXT.BUTTON.RESET_INVENTORY}
              </WorkInventories.Button>
            )}
          </Flex>
        </Accordion.Control>
        <Accordion.Panel>
          <WorkInventories.Table
            columns={columns}
            data={convertedInventoryData ?? []}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </WorkInventories.Accordion>
  );
};
