import { ProductionInstance } from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import {
  ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const WORK_LOGS_KEY = "work_logs";

export const workLogsMutate = createMutationKeys(WORK_LOGS_KEY, {
  cancelInput: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest
  ) => ({
    mutationKey: [
      param.workLogId,
      param.workLogsWorkLogIdCancelInputPutRequest,
    ],
    mutationFn: () => ProductionInstance.workLogsWorkLogIdCancelInputPut(param),
  }),
  cancelPerformance: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest
  ) => ({
    mutationKey: [
      param.workLogId,
      param.workLogsWorkLogIdCancelInputPutRequest,
    ],
    mutationFn: () =>
      ProductionInstance.workLogsWorkLogIdCancelDefectPut(param),
  }),
  cancelDefect: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest
  ) => ({
    mutationKey: [param.workLogId],
    mutationFn: () =>
      ProductionInstance.workLogsWorkLogIdCancelDefectPut(param),
  }),
});

export default workLogsMutate;
