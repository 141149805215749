import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseButton } from "@/components/base/button/BaseButton";
import { EndSetForm } from "@/components/form/setting/EndSetForm";
import { BoxNumberInput } from "@/components/number/boxNumberInput";
import ChangeNumberButton from "@/components/number/changeNumberButton";
import { customFunctions } from "@/config/customFunctions";
import { useModal } from "@/context/ModalStackManager";
import { usePerformanceActions } from "@/hooks/usePerformanceActions.hook";
import useWorkActions from "@/hooks/useWorkActions.hook";
import { useEquipmentStore } from "@/store/equipment.store";
import { useWorkDataStore } from "@/store/work.store";
import { ActionIcon, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconDeviceFloppy, IconSettings } from "@tabler/icons-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


const WorkEndReport = Object.assign({}, {
    Title: BaseTitle,
    Button: BaseButton,
    Input: BoxNumberInput,
    ChangeNumberButton: ChangeNumberButton,
});


const COUNTER_AND_HANDWRITING = ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_HANDWRITING
const COUNTER_AND_AUTO_REPORT = ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_AUTO_REPORT

export const WorkEndReportComponent = () => {

    const { handlePerformanceWithValidation, handlePerformance } = usePerformanceActions();
    const { openModal } = useModal();
    const { t } = useTranslation();

    const { workData } = useWorkDataStore((state) => ({ workData: state.workData }));
    const { work } = workData
    const { equipment } = useEquipmentStore((state) => ({ equipment: state.Equipment }))
    const { onSetting } = useWorkActions({ workData: work });
    const disabled = work?.externalCounterSetting === "COUNTER_AND_AUTO_REPORT"

    const form = useForm({
        initialValues: {
            end: equipment?.currentCount ?? "0",
        },
    });

    useEffect(() => {
        form.setFieldValue("end", equipment?.currentCount ?? "0",);
    }, [equipment?.currentCount ?? "0"]);

    // 실적 저장 + 자동보고 일때 사용
    useEffect(() => {
        if (equipment?.currentCount) {
            const externalCounterSetting = work?.externalCounterSetting ?? COUNTER_AND_HANDWRITING;

            const isCounterAndTemSaveActive =
                externalCounterSetting === COUNTER_AND_AUTO_REPORT &&
                (work?.trackingStatus === "WORKING" || work?.trackingStatus === "PAUSED");

            if (isCounterAndTemSaveActive) {
                handlePerformance(customFunctions.ADD_ACCUMULATION_QUANTITY ?
                    { accQuantity: equipment.currentCount, warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY }
                    : { quantity: equipment.currentCount, warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY }
                );
            }
        }
    }, [work?.externalCounterSetting, equipment?.currentCount, work?.trackingStatus]);

    return (
        <>

            <Flex w={'100%'} justify={'space-between'}>
                <Flex gap={10} align={'center'}>
                    <WorkEndReport.Title fz={"1.625rem"} c={"#FFFFFF"}>{t('생산실적 입력')}</WorkEndReport.Title>
                    <ActionIcon size={"md"}>
                        <IconSettings
                            onClick={() =>
                                openModal(
                                    <EndSetForm
                                        activeType={work?.trackingStatus}
                                        defaultValue={work?.externalCounterSetting ?? COUNTER_AND_HANDWRITING}
                                        onSubmit={onSetting}
                                    />,
                                    "",
                                    ""
                                )
                            }
                        />
                    </ActionIcon>
                </Flex>
                <WorkEndReport.Button
                    size="md"
                    onClick={() => {
                        handlePerformanceWithValidation(form.values.end)
                        form.setValues({ end: "0" });
                    }}
                    disabled={disabled}
                    type={"submit"}
                    rightIcon={<IconDeviceFloppy size={'2rem'} />}
                >{t('실적 저장')}</WorkEndReport.Button>
            </Flex>
            <Flex gap={10} w={'100%'}>
                <WorkEndReport.ChangeNumberButton
                    btnType="decrease"
                    size="lg"
                    color="grape.8"
                    num={5}
                    disabled={disabled}
                    {...form.getInputProps("end")}
                />
                <WorkEndReport.ChangeNumberButton
                    btnType="decrease"
                    size="lg"
                    color="grape.6"
                    num={1}
                    disabled={disabled}
                    {...form.getInputProps("end")}
                />
                <WorkEndReport.Input
                    w={'100%'}
                    size="lg"
                    disabled={disabled}
                    {...form.getInputProps("end")}
                    value={Number(form.values.end)}
                />
                <WorkEndReport.ChangeNumberButton
                    size="lg"
                    color="cyan.6"
                    num={1}
                    disabled={disabled}
                    {...form.getInputProps("end")}
                />
                <WorkEndReport.ChangeNumberButton
                    size="lg"
                    color="indigo.6"
                    num={5}
                    disabled={disabled}
                    {...form.getInputProps("end")}
                />
            </Flex>
        </>
    );
};
