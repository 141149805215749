import { BaseSelect } from "@/test/components/atom/select/BaseSelect";
import { SelectProps } from "@mantine/core";

interface FormSelectProps extends SelectProps {} // BaseSelect에 SelectProps를 확장

const FormSelect: React.FC<FormSelectProps> = ({ ...etcProps }) => {
  return <BaseSelect size="lg" {...etcProps} />;
};

export default FormSelect;
