import { Button, ButtonProps } from '@mantine/core';
import React from 'react';

interface BaseButtonProps extends ButtonProps {
    onClick?: () => void; // 선택적 onClick
}

export const BaseButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
    return (
        <Button size='md' {...props}>
            {children}
        </Button>
    );
};

