import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import { theme } from "@/styles/theme";
import { UserUpdate } from "@/test/components/organism/form/UserUpdateForm";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { routingCode, printCode, equipmentName } = useLocalStorage();
  const { openModal } = useModal();
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Text
        fz={"2.125rem"}
        fs={"normal"}
        fw={700}
        lh={"2.7625rem"}
        c={theme.colors?.gray?.[2]}
      >
        {localStorage.getItem("userName") ?? "-"}
      </Text>
      <Text
        fz={"2.125rem"}
        fs={"normal"}
        fw={700}
        lh={"2.7625rem"}
        c={theme.colors?.gray?.[2]}
      >
        {routingCode !== "undefined" ? routingCode : "-"}
      </Text>
      <div>
        <Text
          onClick={() => openModal(<UserUpdate />, "", "")}
          fz={"2.125rem"}
          fs={"normal"}
          fw={700}
          lh={"2.7625rem"}
          c={theme.colors?.gray?.[2]}
        >
          {equipmentName === null ||
          equipmentName === "null" ||
          equipmentName === "undefined" ||
          equipmentName === undefined
            ? t("*지정된 설비 없음")
            : `${t("설비")} : ${equipmentName}`}
        </Text>
        <Text
          onClick={() => openModal(<UserUpdate />, "", "")}
          fz={"2.125rem"}
          fs={"normal"}
          fw={700}
          lh={"2.7625rem"}
          c={theme.colors?.gray?.[2]}
        >
          {printCode === null ||
          printCode === "null" ||
          printCode === "undefined" ||
          printCode === undefined
            ? t("*지정된 프린터 없음")
            : `${t("프린터")} : ${printCode}`}
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
