import { COMMON_TEXT } from "@/test/constants/text";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
interface MantineDataTableProps<TData extends Record<string, any>>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  onRowClick?: (row: TData) => void;
  data: TData[];
}

export const MantineDataTable = <TData extends Record<string, any>>({
  columns,
  data,
  onRowClick,
  ...etcProps
}: MantineDataTableProps<TData>) => {
  const table = useMantineReactTable<TData>({
    columns,
    data: data,
    defaultColumn: {
      size: 10,
    },
    enableRowSelection: false,
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableStickyHeader: true,
    mantineTableBodyRowProps: (row) => ({
      sx: {
        color: "#FFFFFF",
        cursor: "pointer",
        "&:hover": {
          color: "#000000",
        },
        backgroundColor: "#161B21",
      },
      onClick: () => {
        const data = row.row.original;
        onRowClick && onRowClick(data);
      },
    }),
    localization: {
      noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_DATA,
    },
    mantineTableHeadCellProps: {
      bg: "#161B21",
      style: {
        fontSize: "1.1rem", // 강제 적용
      },
    },
    mantineTableBodyCellProps: ({ cell }) => {
      const cellValue = cell.getValue();
      const isEA = typeof cellValue === "string" && cellValue.includes("EA");
      const numberValue = isEA
        ? parseFloat(cellValue.replace(/[^\d.-]/g, ""))
        : NaN;
      // 'EA' 포함하고 숫자일 경우 오른쪽 정렬 적용
      const isNumber = !isNaN(numberValue);

      return {
        bg: "#161B21",
        style: {
          fontSize: "1.0rem",
          textAlign: isNumber ? "right" : "left", // 숫자일 경우 오른쪽 정렬
        },
      };
    },

    mantineTableProps: {
      sx: {
        tableLayout: "fixed",
        "tbody tr:nth-of-type(1)": {
          width: "100%",
          backgroundColor: "#161B21",
        },
        "tbody tr:nth-of-type(1) > td": {
          fontSize: "1.3rem",
        },
      },
    },
    mantineTableContainerProps: { sx: { maxHeight: "300px" } },
    ...etcProps,
  });

  return <MantineReactTable table={table} />;
};
