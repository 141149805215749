import BaseProgress from "@/components/base/BaseProgress";
import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { useWorkDataStore } from "@/store/work.store";
import { setToLocaleString } from "@/utils/unitMark";
import { Flex } from "@mantine/core";
import { t } from "i18next";


const WorkProgress = Object.assign({}, {
    Title: BaseTitle,
    Text: BaseText,
    Progress: BaseProgress
})


export const WorkProgressInformComponent = () => {

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { work } = workData

    return (
        <>
            <Flex w={"100%"} direction={'column'}>
                <Flex>
                    <WorkProgress.Title size={'1.25rem'} lh={1.55} c={'gray.6'}>
                        {t("진행률")}
                    </WorkProgress.Title>
                    <WorkProgress.Title size={'1.25rem'} lh={1.55} c={'gray.6'}>({t("목표 생산량")} : {setToLocaleString(work?.targetQuantity)} {work?.item?.unitText})</WorkProgress.Title>
                </Flex>
                <Flex w={"100%"} gap={10} align={'center'} justify={'space-between'}>
                    <WorkProgress.Progress
                        w={"90%"}
                        value={Number(Number(work?.summary?.percent).toFixed(2))}
                    />
                    <WorkProgress.Text size={"1.25rem"} c={'white.6'}>
                        {Number(Number(work?.summary?.percent).toFixed(2))}%
                    </WorkProgress.Text>
                </Flex>
            </Flex>
            <Flex justify={"space-between"} mb={8} w={"100%"}>
                {[
                    {
                        title: "생산 수량",
                        color: 'violet.4',
                        children: setToLocaleString(work?.summary?.end),
                    },
                    {
                        title: "양품 수량",
                        color: 'teal.4',
                        children: setToLocaleString(work?.summary?.totalQuantity),
                    },
                    {
                        title: "불량 수량",
                        color: 'red.4',
                        children: setToLocaleString(work?.summary?.defectTotal),
                    },
                    {
                        title: "생산 잔량",
                        color: 'orange.4',
                        children: setToLocaleString(work?.summary?.todoQuantity),
                    },
                ].map((data, index) => (
                    <Flex key={index} p={10} gap={10} direction={'column'} align={'flex-end'}>
                        <WorkProgress.Text
                            fz={"2.125rem"}
                            lh={1.3}
                            c={data.color}
                        >
                            {data.children}
                        </WorkProgress.Text>
                        <WorkProgress.Title
                            fz={"2.125rem"}
                            lh={1.3}
                        >
                            {t(data.title)}
                        </WorkProgress.Title>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}