import WorkProgress from "@/components/base/BaseProgress";
import { useWorkDataStore } from "@/store/work.store";
import ExpandableAccordion from "@/test/components/atom/accordion/expandable/ExpandableAccordion";
import { WorkText } from "@/test/components/atom/text/work/WorkText";
import { WorkTitle } from "@/test/components/atom/title/work/WorkTitle";
import { MantineDataTable } from "@/test/components/molecule/mantineDataTable/MantineDataTable";
import { COMMON_TEXT } from "@/test/constants/text";
import { Accordion } from "@mantine/core";
import {
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { t } from "i18next";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

const WorkTimeTrack = Object.assign(
  {},
  {
    Title: WorkTitle,
    Text: WorkText,
    Table: MantineDataTable,
    Progress: WorkProgress,
    Accordion: ExpandableAccordion,
  }
);

type ExtendedWorkLogs = WorkLogsGet200ResponseRowsInner & { endTime?: string };

export const WorkTimeTrackInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { inputLogsAll } = workData;

  const workTrackLogsData = inputLogsAll
    ?.filter(
      (data) =>
        data.workLogType ===
          WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END ||
        data.workLogType ===
          WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE ||
        data.workLogType ===
          WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME ||
        data.workLogType ===
          WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START
    )
    .reverse();

  const renderWorkTrackLogs = () => {
    return workTrackLogsData
      ?.reduce((acc, row, index, self) => {
        // 일시정지인 경우 작업재개가 있는경우 작업재개를 찾아서 endTime에 종료시간을 추가
        const extendedRow = { ...row } as ExtendedWorkLogs;
        if (extendedRow.workLogType === "TIME_TRACKING_PAUSE") {
          const nextResumeIndex = self
            .slice(index + 1)
            .findIndex((r) => r.workLogType === "TIME_TRACKING_RESUME");
          if (nextResumeIndex !== -1) {
            extendedRow.endTime = self[index + nextResumeIndex + 1].createdAt;
          }
        } else if (extendedRow.workLogType === "TIME_TRACKING_END") {
          extendedRow.endTime = extendedRow.createdAt;
        }
        // 작업로그 중 작업 재개가 아닌경우 배열(acc)에 추가
        if (extendedRow.workLogType !== "TIME_TRACKING_RESUME") {
          acc.push(extendedRow);
        }
        return acc;
      }, [] as ExtendedWorkLogs[])
      .map((row) => {
        const workLogTypeText =
          row.workLogType === "TIME_TRACKING_START"
            ? COMMON_TEXT.WORK.START
            : row.workLogType === "TIME_TRACKING_END"
            ? COMMON_TEXT.WORK.END
            : row.workLogType === "TIME_TRACKING_PAUSE"
            ? COMMON_TEXT.WORK.PAUSED
            : "";
        const startTime =
          row.workLogType !== "TIME_TRACKING_END"
            ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
            : "-";
        const endTime = row.endTime
          ? dayjs(row.endTime).format("YYYY-MM-DD HH:mm:ss")
          : "-";
        const downtimeReasonName =
          row.workLogType === "TIME_TRACKING_PAUSE"
            ? (row.downtimeReasonName as string)
            : "-";

        return {
          type: t(workLogTypeText),
          startTime,
          endTime,
          downtimeReasonName,
        };
      });
  };

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "type",
        header: COMMON_TEXT.COLUMN.TYPE,
      },
      {
        accessorKey: "startTime",
        header: COMMON_TEXT.COLUMN.START_TIME,
      },
      {
        accessorKey: "endTime",
        header: COMMON_TEXT.COLUMN.DOWNTIME_REASON,
      },
      {
        accessorKey: "downtimeReasonName",
        header: COMMON_TEXT.COLUMN.DOWNTIME_REASON,
      },
    ],
    []
  );

  return (
    <WorkTimeTrack.Accordion>
      <Accordion.Item value="workTable">
        <Accordion.Control>
          <WorkTimeTrack.Title fz={"1.625rem"} c={"#FFFFFF"}>
            {COMMON_TEXT.TITLE.WORK_TIME_TRACK_TITLE}
          </WorkTimeTrack.Title>
        </Accordion.Control>
        <Accordion.Panel>
          <WorkTimeTrack.Table
            columns={columns}
            data={renderWorkTrackLogs() ?? []}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </WorkTimeTrack.Accordion>
  );
};
