import BaseAccordion from "@/components/base/BaseAccordion"
import BaseProgress from "@/components/base/BaseProgress"
import { BaseText } from "@/components/base/BaseText"
import { BaseTitle } from "@/components/base/BaseTitle"
import { BaseButton } from "@/components/base/button/BaseButton"
import { BaseTable } from "@/components/base/table/BaseTable"
import { usePerformanceActions } from "@/hooks/usePerformanceActions.hook"
import { useWorkDataStore } from "@/store/work.store"
import { setToLocaleString } from "@/utils/unitMark"
import { Flex } from "@mantine/core"
import { IconSeparatorVertical } from "@tabler/icons-react"
import { t } from "i18next"

const WorkCurrentLot = Object.assign({}, {
    Button: BaseButton,
    Title: BaseTitle,
    Text: BaseText,
    Table: BaseTable,
    Progress: BaseProgress,
    Accordion: BaseAccordion
})

export const WorkCurrentLotComponent = () => {

    const { handlePalletSeparate } = usePerformanceActions();
    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));

    const { work, lots } = workData

    return (
        <>
            <Flex w={"100%"}>
                <Flex w={'100%'} direction={'row'} align={'center'} justify={'space-between'}>
                    <WorkCurrentLot.Title
                        fz={"1.625rem"}
                        c={"#FFFFFF"}
                    >
                        {t("현재 로트 진행도")}
                        ({t("로트 번호")} : {lots?.name ? lots?.name.split('#').pop() : "-"})
                    </WorkCurrentLot.Title>
                    <WorkCurrentLot.Button
                        disabled={work?.currentLotSummary?.totalQuantity === '0'}
                        size="md"
                        onClick={handlePalletSeparate}
                        color={'grape.6'}
                        rightIcon={<IconSeparatorVertical size={'2rem'} />}
                    >
                        {t('파레트 분리')}
                    </WorkCurrentLot.Button>
                </Flex>
            </Flex>
            <Flex justify={"space-between"} mb={8} w={"100%"}>
                {[
                    {
                        title: "생산 수량",
                        color: 'violet.4',
                        children: setToLocaleString(work?.currentLotSummary?.end)
                    },
                    {
                        title: "양품 수량",
                        color: 'teal.4',
                        children: setToLocaleString(work?.currentLotSummary?.totalQuantity)
                    },
                    {
                        title: "불량 수량",
                        color: 'red.4',
                        children: setToLocaleString(work?.currentLotSummary?.defectTotal)
                    },

                ].map((data, index) => (
                    <Flex key={index} p={10} gap={10} direction={'column'} align={'flex-end'}>
                        <WorkCurrentLot.Text
                            fz={"2.125rem"}
                            lh={1.3}
                            color={data.color}
                        >
                            {data.children}
                        </WorkCurrentLot.Text>
                        <WorkCurrentLot.Title
                            fz={"2.125rem"}
                            lh={1.3}

                        >
                            {t(data.title)}
                        </WorkCurrentLot.Title>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}