import { theme } from "@/styles/theme";
import { Accordion } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";


interface BaseAccordionProps {
    children: React.ReactNode
}


const BaseAccordion = ({ children }: BaseAccordionProps) => {
    return (
        <Accordion
            w={"100%"}
            chevron={<IconPlus size="2.5rem" />}
            styles={
                {
                    item: {
                        border: `1px solid ${theme?.colors?.gray?.[7]}`,
                    },
                    control: {
                        ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
                    },
                    chevron: {
                        color: "white",
                        '&[data-rotate]': {
                            transform: "rotate(45deg)"
                        },
                    }
                }
            } // 스타일 객체를 함수 호출로 전달
        >
            {children}
        </Accordion>
    );
};

export default BaseAccordion;