import { theme } from "@/styles/theme";
import { BaseBox } from "@/test/components/atom/box/BaseBox";
import { BoxProps } from "@mantine/core";

export const OutsourceInformBox = (props: BoxProps) => {
    const { children, ...etcProps } = props;

    return (
        <BaseBox
            style={{
                borderRadius: "2px",
                background: theme.colors?.red?.[6],
                display: "flex",
                padding: "1px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                color: theme.colors?.white?.[0],
            }}
            {...etcProps}
        >
            {children}
        </BaseBox>
    );
};
