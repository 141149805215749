import { BaseText } from "@/test/components/atom/text/BaseText";
import { TextProps } from "@mantine/core";

export const WorkText = ({ ...etcProps }: TextProps) => {

    return (
        <BaseText
            color={'white.0'}
            fz={'1.25rem'}
            fs={'normal'}
            fw={700}
            lh={1.55}
            {...etcProps}
        />
    );
};
