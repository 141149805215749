import { theme } from "@/styles/theme";
import { Progress, ProgressProps } from "@mantine/core";
import React from "react";

interface ProgressBarProps extends ProgressProps, React.RefAttributes<HTMLDivElement> { }

const StatusProgressBar = (props: ProgressBarProps) => {

    const { value } = props;

    const progressColor = () => {
        if (value && value >= 70) return "#009000";
        return `${theme.colors?.blue?.[7]}`;
    };

    return (
        <Progress
            animate
            color={progressColor()}
            styles={{
                root: {
                    width: '47.8125rem',
                    height: '2rem'
                },
                bar: {
                    maxWidth: "100%",
                },
            }}
            value={value}
            bg={'gray.5'}
            {...props}
        />
    );
};

export default StatusProgressBar;
