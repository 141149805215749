import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { customFunctions } from "@/config/customFunctions";
import { useWorkDataStore } from "@/store/work.store";
import { getWorkTrackingStatus } from "@/utils/tranferWorkStatus";
import { Badge, Flex } from "@mantine/core";
import { t } from "i18next";

const WorkInform = Object.assign({}, {
    Title: BaseTitle,
    Text: BaseText,
})

export const WorkInformComponent = () => {

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { work, lots } = workData

    return (
        <>
            <Flex justify={"space-between"} w={'100%'}>
                <Flex direction={'column'}>
                    <WorkInform.Title>{t("현재 작업 상태")}</WorkInform.Title>
                    <WorkInform.Text>
                        <Badge size='xl' color={getWorkTrackingStatus(work?.trackingStatus).color}>
                            {getWorkTrackingStatus(work?.trackingStatus).status}
                        </Badge>
                    </WorkInform.Text>
                </Flex>
                <Flex direction={'column'}>
                    <WorkInform.Title>
                        {t("품목코드")}
                    </WorkInform.Title>
                    <WorkInform.Text size={"1.25rem"} c={'white.6'}>{work?.item?.code ?? "-"}</WorkInform.Text>
                </Flex>
                <Flex direction={'column'}>
                    <WorkInform.Title size={'1.25rem'} lh={1.55} c={'gray.6'}>{t("품목명")}</WorkInform.Title>
                    <WorkInform.Text size={"1.25rem"} c={'white.6'}>{work?.item?.name ?? "-"}</WorkInform.Text>
                </Flex>
                {
                    customFunctions.ADD_TEMP_FUNCTION_ENABLED &&
                    <Flex direction={'column'}>
                        <WorkInform.Title size={'1.25rem'} lh={1.55} c={'gray.6'}>{t("현재 로트 번호")}</WorkInform.Title>
                        <WorkInform.Text size={"1.25rem"} c={'white.6'}>{lots?.name ?? "-"}</WorkInform.Text>
                    </Flex>
                }
            </Flex>
            <Flex justify={"space-between"} w={'100%'}>
                {[
                    { title: "규격", value: work?.item?.spec },
                    { title: "가로", value: work?.item?.width },
                    { title: "세로", value: work?.item?.depth },
                    { title: "높이", value: work?.item?.height },
                    { title: "비고", value: work?.description },
                ].map((item, index) => {
                    return (
                        <Flex key={index} gap={10}>
                            <WorkInform.Title size={'1.25rem'} lh={1.55} c={'gray.6'}>{t(item.title)}</WorkInform.Title>
                            <WorkInform.Text size={"1.25rem"} c={'white.6'}>{item.value}</WorkInform.Text>
                        </Flex>
                    );
                })}
            </Flex>
        </>
    )
}