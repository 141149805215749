import { BaseTitle } from "@/test/components/atom/title/BaseTitle"
import { TitleProps } from "@mantine/core"

export const WorkTitle = ({ ...etcProps }: TitleProps) => {

    return (
        <BaseTitle
            fz={'1.25rem'}
            fw={700}
            lh={1.55}
            c={'gray.6'}
            fs={'normal'}
            ff={'Noto Sans'}
            {...etcProps}
        />
    )
}