import { create, StateCreator } from "zustand"

export type Equipment = {
  name: string
  code: string
  currentCount?: string
  limitCount?: string
  isLimitHit?: boolean
}

export type EquipmentState = {
  Equipment: Equipment | null
}

export type EquipmentCountData = Pick<Equipment, "currentCount" | "limitCount" | "isLimitHit">

export interface EquipmentActions {
  setEquipment: (equipment: Equipment | null) => void
  updateCurrentCount: (countData: EquipmentCountData) => void
}

export type EquipmentPersist = (config: StateCreator<EquipmentState>) => StateCreator<EquipmentState>
export interface EquipmentStore extends EquipmentState, EquipmentActions { }

const useEquipmentStore = create<EquipmentStore>()((set) => ({
  Equipment: null,
  setEquipment: (equipment: Equipment | null) => {
    set({ Equipment: equipment })
  },
  updateCurrentCount: (countData: EquipmentCountData) => {
    set((state) => {
      const { currentCount, limitCount, isLimitHit } = countData

      if (state.Equipment) {
        return {
          Equipment: {
            ...state.Equipment,
            currentCount,
            limitCount,
            isLimitHit
          },
        }
      }
      return state
    })
  },
}))

export { useEquipmentStore }
