import { BaseButton } from "@/test/components/atom/button/BaseButton";
import { ButtonProps } from "@mantine/core";


interface CancelButtonProps<T> extends ButtonProps {
    onCancel?: () => void;
    children?: React.ReactNode;
}

export const CancelButton = <T,>({ onCancel, children, ...etcProps }: CancelButtonProps<T>) => {
    return (
        <BaseButton
            onClick={onCancel}
            variant="outline"
            color="gray"
            size={'md'}
            {...etcProps}
        >{children}</BaseButton>
    );
};