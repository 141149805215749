// src/hooks/useConfirmModal.js
import { Confirm } from "@/components/modal/ConfirmModal";
import { customFunctions } from "@/config/customFunctions";
import { useModal } from "@/context/ModalStackManager";
import i18n from "@/language/i18n";
import { useWorkDataStore } from "@/store/work.store";
import { InventoriesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";

const useConfirmModal = () => {

    const { openModal } = useModal();
    const { t } = useTranslation();
    const { workData } = useWorkDataStore((state) => ({ workData: state.workData }));
    const { work } = workData

    const confirmModal = ({ message, titleKey }: { message: string | JSX.Element, titleKey: string }) => {
        const translatedMessage = typeof message === 'string' ? t(message) : message;
        const translatedTitle = t(titleKey);
        return openModal(<Confirm message={translatedMessage} />, '', translatedTitle, false)
    }

    const confirmPreviousOrderClosureModal = () => confirmModal({
        message: '이전 작업 지시서가 종료되지 않았습니다. 종료하시겠습니까?',
        titleKey: '작업 지시서 종료'
    });

    const confirmProductionRecordEnteredModal = () => confirmModal({
        message: '투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?',
        titleKey: "생산실적 입력 여부"
    });

    const confirmOutOfStockModal = () => confirmModal({
        message: '재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?',
        titleKey: '재고 부족 여부 확인'
    });

    const confirmPreviousMaterialUsedModal = () => confirmModal({
        message: '이전 자재를 사용 완료 하였습니까?',
        titleKey: '재고 사용 여부 확인'
    });

    const confirmWorkClosureModal = () => confirmModal({
        message: (BigNumber(work?.targetQuantity as string).isGreaterThan(work?.summary?.totalQuantity as string)
            && customFunctions.END_QUANTITY_MISS_MATCH)
            ? '작업지시 수량보다 생산량이 적습니다. 작업을 종료하시겠습니까?'
            : '작업을 종료하시겠습니까?',
        titleKey: '작업 종료'
    });

    const confirmInputCancellationModal = () => confirmModal({
        message: '투입을 취소하시겠습니까?',
        titleKey: '투입 취소'

    })

    const confirmLimitHitModal = ({ unitText, equipmentLimitCounter, defectTotal }: {
        unitText: string | undefined,
        equipmentLimitCounter: string | undefined,
        defectTotal?: string
    }) => {

        const limitHitMessage = <div>
            <div>{i18n.t("리밋 카운터에 도달하였습니다. 실적을 저장하시겠습니까?")}</div>
            <div>{i18n.t("생산 수량")} : {equipmentLimitCounter} ({unitText})</div>
            {
                customFunctions.ADD_TEMP_FUNCTION_ENABLED && (
                    defectTotal ?
                        <div>{i18n.t("총 불량수량")} : {defectTotal} ({unitText})</div> : undefined
                )
            }
        </div>

        return confirmModal({
            message: limitHitMessage,
            titleKey: "작업 제한 횟수 도달"
        })
    }

    const confirmFlushModal = ({ workInventoriesData }: { workInventoriesData: InventoriesGet200Response | undefined }) => {
        const message = workInventoriesData?.rows?.map((row) => (
            `${row.itemName}(${row.itemCode}) 재고가 ${row.quantity} ${row.unitText} 남아 있습니다. 초기화 하시겠습니까?`
        )).join('\n') as string;

        return confirmModal({
            message: message,
            titleKey: '작업 종료 확인'
        })
    }

    return {
        confirmPreviousOrderClosureModal,
        confirmProductionRecordEnteredModal,
        confirmOutOfStockModal,
        confirmPreviousMaterialUsedModal,
        confirmWorkClosureModal,
        confirmInputCancellationModal,
        confirmLimitHitModal,
        confirmFlushModal,
    };

}

export default useConfirmModal;