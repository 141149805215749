import { LocalStorageProvider } from "@/context/LocalStorageProvider";
import ModalStackManager from "@/context/ModalStackManager";
import { WorksSearchProvider } from "@/context/WorksSearchProvider";
import i18n from "@/language/i18n";
import QueryProvider from "@/provider/QueryProvider";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { IoProvider } from "socket.io-react-hook";
import App from "./App";
import GlobalStyle from "./styles/Global";
import { theme } from "./styles/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <LocalStorageProvider>
        <IoProvider>
          <RecoilRoot>
            <I18nextProvider i18n={i18n}>
              <QueryClientProvider client={queryClient}>
                <QueryProvider>
                  <ModalStackManager>
                    <MantineProvider theme={theme}>
                      <WorksSearchProvider>
                        <Notifications />
                        <GlobalStyle />
                        <App />
                      </WorksSearchProvider>
                    </MantineProvider>
                  </ModalStackManager>
                </QueryProvider>
              </QueryClientProvider>
            </I18nextProvider>
          </RecoilRoot>
        </IoProvider>
      </LocalStorageProvider>
    </React.StrictMode>
  </BrowserRouter>
);
