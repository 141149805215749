import { useQuery } from "react-query";

import DefaultInstance from "@/instance/axios";
import { DowntimeReasonsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

//downtimeReasons 으로 싹다 바꿔야함
export const GET_DOWNTIME_REASONS_QUERY_KEY = "downtimeReasons-get";
export type DownTimeReasonsOption = {
  value: string;
  label: string;
  group: string;
};
const getAutoCompleteKeyword = (keyword: string) =>
  DefaultInstance.downtimeReasonsFindPost(
    {
      search: keyword,
      searchFields: ["name", "code"],
      sort: 'seq',
    }
  ).then((res: AxiosResponse<DowntimeReasonsGet200ResponseRowsInner[]>) => res.data);

export const useDownTimeReasonsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: DownTimeReasonsOption[]) => void;
  }
) => {
  return useQuery(
    ["downTimeReasons-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword),
    {
      enabled: focus,
      select: (data: DowntimeReasonsGet200ResponseRowsInner[]) =>
        (data?.map((item) => {
          return {
            value: item.code || "",
            label: item.name || "",
            group: String(item.operationCode ?? '공정 없음'),
          };
        }) || []) as DownTimeReasonsOption[],
      onSuccess: options?.onSuccess,
    }
  );
};

const getDownTimeReasonsByCode = (code: string | null) =>
  DefaultInstance.downtimeReasonsFindPost(
    {
      ...(code && { search: code }),
      searchFields: ["code"],
      sort: 'seq',
    }
  ).then((res: AxiosResponse<DowntimeReasonsGet200ResponseRowsInner[]>) => res.data);

export const useDownTimeReasonsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: DownTimeReasonsOption[]) => void;
  }
) => {
  return useQuery(["items-get-code", code], () => getDownTimeReasonsByCode(code), {
    enabled: enabled ?? false,
    select: (data: DowntimeReasonsGet200ResponseRowsInner[]) =>
      data?.map((item) => {
        return {
          value: item.code || '',
          label: item.name || '',
          group: String(item.operationCode ?? '공정 없음')
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
