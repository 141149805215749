import { t } from "i18next";

export const COMMON_TEXT = {
  ACTION: {
    CANCEL: t("취소"),
    CANCELLED: t("취소됨"),
  },

  TITLE: {
    WORK_TIME_TRACK_TITLE: t("작업 시간 추적"),
    LOCATION_TITLE: t("로케이션"),
    PRODUCTABLE_INVENTORY_TITLE: t("생산 가능재고"),
    FROM_LOCATION_TITLE: t("원부자재 로케이션"),
    TO_LOCATION_TITLE: t("재공품 입고 로케이션"),
    TARGET_LOCATION_TITLE: t("생산 로케이션"),
    OUTSOURCE_TITLE: t("외주"),
    LOT_NUMBER: t("로트 번호"),
    CURRENT_LOT_PROGRESS: t("현재 로트 진행도"),
    CURRENT_LOT_DEFECT: t("현재 로트 불량기록"),
    CURRENT_DEFECT: t("불량 기록"),
    TEMPORARY_LOT_DEFECT: t("임시 로트 불량기록"),
    DEFECT_REPORT: t("불량 보고"),
    PRODUCTION_INPUT: t("생산실적 입력"),
    CALENDAR: t("날짜 선택"),
    MAIN_TITLE: t("생산 POP"),
  },

  WORK: {
    CURRENT_WORK_STATUS: t("현재 작업 상태"),
    ITEM_CODE: t("품목코드"),
    ITEM_NAME: t("품목명"),
    CURRENT_LOT_NUMBER: t("로트번호"),
    SPEC: t("규격"),
    WIDTH: t("가로"),
    DEPTH: t("세로"),
    HEIGHT: t("높이"),
    DESCRIPTION: t("비고"),
    PROGRESS: t("진행률"),
    TARGET_QUANTITY: t("목표 생산량"),
    PRODUCTION_QUANTITY: t("생산 수량"),
    TOTAL_QUANTITY: t("양품 수량"),
    DEFECT_QUANTITY: t("불량 수량"),
    TODO_QUANTITY: t("생산 잔량"),

    PAUSED: t("일시정지"),
    START: t("작업시작"),
    END: t("작업종료"),
  },

  LABEL: {
    DELIVERY_LOCATION_CODE: t("출고 로케이션 코드"),
    TARGET_LOCATION_CODE: t("입고 로케이션 코드"),
    INPUT_BARCODE_INFORM: t("바코드 정보 입력"),
    LOT_INFORM: t("로트 정보"),
    DEFECT_LOCATION: t("불량 창고"),
    INVENTORY: t("재고"),
    QUANTITY: t("수량"),
    TARGET_LOCATION: t("이동 창고"),
    ITEM: t("품목"),
    USER_ID: t("사용자 ID"),
    USER_NAME: t("사용자 이름"),
    EQUIPMENT: t("설비"),
    LOCATION: t("로케이션"),
    PRINTER: t("프린터"),
    PASSWORD: t("비밀번호"),
  },

  BUTTON: {
    RAW_MATERIAL_INPUT: t("원부자재 투입"),
    RAW_MATERIAL_ALL_INPUT: t("원부자재 일괄 투입"),
    INPUT_LOGS: t("투입 기록"),
    RECORD_INSPECT: t("초중종품 검사"),
    DEFECT_RECYCLE: t("불량 재활용"),
    WORK_LOGS: t("작업 로그"),
    INSPECT_LOGS: t("검사 기록"),
    TRANSFER_ALL: t("벌크 이동"),
    OUTGOING_ALL: t("벌크 출고"),
    RESET_INVENTORY: t("재고 초기화"),
    PALLET_SEPARATE: t("파레트 분리"),
    DEFECT_REPORT: t("불량 보고"),
    PRODUCTION_SAVE: t("생산실적 저장"),
    LOGIN: t("로그인"),
  },

  COLUMN: {
    ITEM_CODE: t("품목코드"),
    ITEM_NAME: t("품목명"),
    ROUTING_CODE: t("라우팅 코드"),
    LOT_NAME: t("로트명"),
    LOT_ID: t("로트ID"),
    LOT_EXPIRATION_DATE: t("로트유효기한"),
    LOT_LOCATION: t("로트위치"),
    RELEASE_LOCATION: t("출고로케이션"),
    QUANTITY: t("수량"),
    INPUT_LOCATION: t("입고로케이션"),
    WORKER: t("작업자"),
    CREATED_AT: t("일시"),
    ACTION: t("액션"),
    LOG_NUM: t("로그 번호"),
    RELATED_LOG_NUM: t("연관 로그 번호"),
    TYPE: t("타입"),
    ORDER: t("순서"),
    SCHEDULE_SEQ: t("작업 순서"),
    EQUIPMENT_NAME: t("설비명"),
    LINE_NAME: t("라인명"),
    TARGET_QUANTITY: t("계획 수량"),
    SPEC: t("규격"),
    SCHEDULED_AT: t("작업일"),
    TRACKING_STATUS: t("진행 상태"),
    DESCRIPTION: t("비고"),
    INSPECT_SUMMARY: t("검사 기준 요약"),
    PASS_OR_NOT: t("합격 여부"),
    LOCATION: t("위치"),
    INSPECTION_AT: t("측정일"),
    FINAL_QUANTITY: t("최종 재고"),
    CLOSED_QUANTITY: t("마감 재고"),
    CLOSED_AT: t("마감 일시"),
    INCREASE_QUANTITY: t("추가된 재고"),
    DECREASE_QUANTITY: t("감소된 재고"),
    DELTA_QUANTITY: t("재고 변화량"),
    START_TIME: t("시작 시간"),
    END_TIME: t("종료 시간"),
    DOWNTIME_REASON: t("비가동 사유"),
    REMAIN_QUANTITY: t("잔량"),
    DEFECT_NAME: t("불량명"),
    DEFECT_QUANTITY: t("불량수량"),
    GRADE: t("GRADE"),
    COLOR: t("색상"),
    SHAPE: t("형태"),
  },

  FORM: {
    INPUT_LOGS_TITLE: t("투입 기록 확인"),
    RECORD_INSPECT_TITLE: t("검사 기록 확인"),
    RECYCLE_LOGS_TITLE: t("불량 재활용"),
    WORK_LOGS_TITLE: t("작업 로그 확인"),
    INSPECT_LOGS_TITLE: t("검사 기록 보고"),
    RAW_MATERIAL_TITLE: t("원부자재 투입"),
    RAW_MATERIAL_ALL_TITLE: t("원부자재 일괄 투입"),
    RECYCLABLE_DEFECT_TITLE: t("재활용 할 불량"),
    RECYCLED_DEFECT_TITLE: t("재활용 될 불량"),
    OUTGOING_ALL_TITLE: t("재고 벌크 출고"),
    TRANSFER_ALL_TITLE: t("재고 벌크 이동"),

    RAW_MATERIAL_ALL_SUBTITLE: t("바코드 LOT 입력"),

    INPUT_LOGS_DESCRIPTION: t("원/부자재 투입 기록을 확인합니다."),
    WORK_LOGS_CANCEL_DESCRIPTION: t(
      "취소 버튼을 클릭하여 특정 투입을 취소할 수 있습니다."
    ),
    WORK_LOGS_DESCRIPTION: t("작업 로그(투입, 실적, 불량)를 확인합니다."),
    RAW_MATERIAL_ALL_DESCRIPTION: `${t(
      "바코드를 스캔하여 자동으로 데이터를 입력합니다."
    )} ${t("일괄 투입 버튼을 클릭하여 원부자재를 일괄 투입합니다.")}`,
    TRANSFER_ALL_DESCRIPTION: `${t(
      "바코드를 스캔하여 자동으로 데이터를 입력합니다."
    )} ${t("벌크 이동 버튼을 클릭하여 이동을 완료합니다.")}`,
    OUTGOING_ALL_DESCRIPTION: `${t(
      "바코드를 스캔하여 자동으로 데이터를 입력합니다."
    )} ${t("등록 버튼을 클릭하여 일괄 출고를 완료합니다.")}`,
  },

  INSPECT: {
    PASS: "합격",
    FAIL: "불합격",
    NO_RESPONSE: "응답없음",
    POINT: "포인트",
    CREATOR: "검사자",
    CREATED_AT: "검사날짜",
    INITIAL_PRODUCT: "초물",
    INTERMEDIATE_PRODUCT: "종물",
    FINAL_PRODUCT: "종물",
    INSPECTING: "검사중",
  },

  NOTIFICATION: {
    INSPECT_SUCCESS: t("초중종품 검사가 성공적으로 되었습니다."),
    WORK_CANCEL: t("해당 작업을 취소하시겠습니까?"),
    RAW_MATERIAL_INPUT_SUCCESS: t("원/부자재가 성공적으로 투입되었습니다."),
    RAW_MATERIAL_INPUT_FAIL: t("원/부자재 투입에 실패하였습니다."),
    LOT_NOT_FOUND: t("바코드에 해당하는 로트가 존재하지 않습니다."),
    DUPLICATE_LOT: t("이미 추가된 LOT입니다."),
    TRANSFORM: t("변환되었습니다"),
    TRANSFORM_FAIL: t("변환에 실패하였습니다."),
    NO_LOCATION: t("사용자의 로케이션 또는 출고 로케이션을 선택해주세요."),
    OUTGOING_ALL_SUCCESS: t("벌크 이동이 성공적으로 되었습니다."),
    OUTGOING_ALL_FAIL: t("벌크 이동이 실패하였습니다 "),
    TRANSFER_ALL_SUCCESS: t("벌크 출고가 성공적으로 되었습니다."),
    TRANSFER_ALL_FAIL: t("벌크 출고가 실패하였습니다 "),
    USER_MODIFY: t("사용자 정보가 수정되었습니다."),
    LOGIN_FAIL: t("로그인에 실패하였습니다."),
  },

  DATA_EMPTY: {
    NO_DATA: t("데이터가 없습니다."),
    NO_LOT_INFORM: t("바코드 스캔 또는 로트정보를 입력하여 LOT를 등록하세요."),
  },

  VALIDATE: {
    LOCATION: t("창고를 선택해주세요"),
    INVENTORY: t("재고를 선택해주세요"),
    QUANTITY: t("수량을 입력해주세요"),
    ITEM: t("품목을 선택해주세요"),
  },

  STATUS: {
    WAITING: t("작업대기"),
    PAUSED: t("일시정지"),
    WORKING: t("작업중"),

    DONE: t("작업완료"),
  },

  PLACEHOLDER: {
    ITEMS: t("품목을 선택해주세요"),
    SPEC: t("규격을 선택해주세요"),
  },
};
