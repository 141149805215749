import { theme } from "@/styles/theme";
import { BaseAccordion } from "@/test/components/atom/accordion/BaseAccordion";
import { AccordionProps } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

interface ExpandablePanelProps extends AccordionProps { }

const ExpandableAccordion = (props: ExpandablePanelProps) => {
    const { children, ...etcProps } = props
    return (
        <BaseAccordion
            w={'100%'}
            chevron={<IconPlus size="2.5rem" />}
            styles={
                {
                    item: {
                        border: `1px solid ${theme?.colors?.gray?.[7]}`,
                    },
                    control: {
                        ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
                    },
                    chevron: {
                        color: "white",
                        '&[data-rotate]': {
                            transform: "rotate(45deg)"
                        },
                    }
                }
            }
            {...etcProps}
        >
            {children}
        </BaseAccordion>
    );
};

export default ExpandableAccordion;