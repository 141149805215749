import { SpcInstance } from '@/instance/axios';
import { SpecificationsGet200Response } from '@sizlcorp/sizl-api-document/dist/models';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

export const GET_SPC_QUERY_KEY = 'get-spc';

const getSpcFetch = (params?: any) => {
  if (params) {
    const queryString = JSON.stringify(params.query);
    return SpcInstance.specificationsGet({
      query: [queryString],
      search: params.search,
      searchFields: params.searchFields,
      page: params.activePage ?? 1,
      pageSize: 10,
    });
  } else return SpcInstance.specificationsGet();
};

const useSpcGetQuery = (
  params?: any,
  options?: {
    onSuccess: (data: any) => void;
  }
) => {
  return useQuery<AxiosResponse<SpecificationsGet200Response, any[]>>({
    queryKey: params
      ? [
        GET_SPC_QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
        params.searchFields,
        params.search,
      ]
      : [GET_SPC_QUERY_KEY],
    // refetchInterval(data, query) {
    //   return 5000;
    // },
    queryFn: () => (params ? getSpcFetch(params) : getSpcFetch()),
  });
};

export default useSpcGetQuery;
