
import { BaseTitle } from "@/test/components/atom/title/BaseTitle"
import { TitleProps } from "@mantine/core"

export const FormSubTitle = (props: TitleProps) => {

    return (
        <BaseTitle
            size={"1.5rem"} c={"#000000"} weight={400} lh={"130%"}
            {...props}
        />
    )
}