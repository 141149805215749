import { GET_INVENTORIES_QUERY_KEY } from "@/api/inventories/useInventoriesGetQuery";
import { WORK_LOG_GET_KEY } from "@/api/logs/useWorkLogsGetQuery";
import DefaultInstance, { ProductionInstance } from "@/instance/axios";
import {
  DefaultApiWmsBatchPostRequest,
  DefaultApiWmsIncomingPostRequest,
  DefaultApiWmsOutgoingPostRequest,
  DefaultApiWmsTransferPostRequest,
  ProductionActionApiWorksWorkIdInputPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";


interface IncomingParams extends DefaultApiWmsIncomingPostRequest { }
interface OutgoingParams extends DefaultApiWmsOutgoingPostRequest { }
interface OutgoingAllParams extends DefaultApiWmsBatchPostRequest { }
interface TransferParams extends DefaultApiWmsTransferPostRequest { }
interface TransferAllParams extends DefaultApiWmsBatchPostRequest { }
interface WorkInputParams
  extends ProductionActionApiWorksWorkIdInputPutRequest { }

type MutationType = "incoming" | "outgoing" | "outgoingAll" | "transfer" | "transferAll" | "workInput";

type Params<T extends MutationType> = T extends "incoming"
  ? IncomingParams
  : T extends "outgoing"
  ? OutgoingParams
  : T extends "outgoingAll"
  ? OutgoingAllParams
  : T extends "transfer"
  ? TransferParams
  : T extends "transferAll"
  ? TransferAllParams
  : WorkInputParams;

const incomingFetcher = (params: IncomingParams) =>
  DefaultInstance.wmsIncomingPost(params);

const outgoingFetcher = (params: OutgoingParams) =>
  DefaultInstance.wmsOutgoingPost(params);

const outgoingAllFetcher = (params: OutgoingAllParams) =>
  DefaultInstance.wmsBatchPost(params);

const transferFetcher = (params: TransferParams) =>
  DefaultInstance.wmsTransferPost(params);

const transferAllFetcher = (params: TransferAllParams) =>
  DefaultInstance.wmsBatchPost(params);

const workInputFetcher = (params: WorkInputParams) =>
  ProductionInstance.worksWorkIdInputPut(params);

const useInventoryMutation = <T extends MutationType>(type: T): any => {
  const queryClient = useQueryClient();

  const incomingMutation = useMutation(
    (params: Params<typeof type>) => incomingFetcher(params as IncomingParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
        queryClient.invalidateQueries('inventories');
      },
    }
  );

  const outgoingMutation = useMutation(
    (params: Params<typeof type>) => outgoingFetcher(params as OutgoingParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const outgoingAllMutation = useMutation(
    (params: Params<typeof type>) => outgoingAllFetcher(params as OutgoingAllParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const transferMutation = useMutation(
    (params: Params<typeof type>) => transferFetcher(params as TransferParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const transferAllMutation = useMutation(
    (params: Params<typeof type>) => transferAllFetcher(params as TransferAllParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const workInputMutation = useMutation(
    (params: Params<typeof type>) =>
      workInputFetcher(params as WorkInputParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
        // 투입기록을 업데이트 하기위한 queryClient 추가
        queryClient.invalidateQueries(WORK_LOG_GET_KEY);
      },
    }
  );

  if (type === "incoming") {
    return incomingMutation;
  } else if (type === "outgoing") {
    return outgoingMutation;
  } else if (type === "outgoingAll") {
    return outgoingAllMutation;
  } else if (type === "transfer") {
    return transferMutation;
  } else if (type === "transferAll") {
    return transferAllMutation;
  } else if (type === "workInput") {
    return workInputMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useInventoryMutation;
