import { useModal } from "@/context/ModalStackManager";
import styled from "@emotion/styled";
import { DatePicker } from "@mantine/dates";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";

interface DateRangePickerProps {
  dates: [Date | null, Date | null];
}

const DateRangePicker = ({ dates }: DateRangePickerProps) => {
  const { closeModal } = useModal();

  const [value, setValue] = useState<[Date | null, Date | null]>([
    dates[0],
    dates[1],
  ]);

  // 날짜 범위 선택
  const handleChange = (e: any) => {
    setValue(e);
    // 두 번째 날짜가 선택되었을 때 모달을 닫는다.
    if (e[1] !== null) {
      closeModal(e);
    }
  };

  return (
    <MyDatePicker
      type="range"
      allowSingleDateInRange // 동일날짜 선택가능
      value={value}
      onChange={(e) => handleChange(e)}
      w={800}
      h={720}
      size="xl"
      previousIcon={<IconChevronLeft />}
      nextIcon={<IconChevronRight />}
      locale="ko"
      monthLabelFormat="MM/YYYY"
    />
  );
};

const MyDatePicker = styled(DatePicker)`
  .mantine-UnstyledButton-root {
    font-size: 3rem;
  }

  .mantine-DatePicker-calendarHeader {
    max-width: calc(7rem * 7);
    margin-bottom: 2rem;
  }

  .mantine-Day-day {
    width: 7rem;
    height: 7rem;
    font-size: 4rem;
  }

  .mantine-DatePicker-weekday {
    font-size: 3rem;
  }

  .mantine-YearLevel-yearLevel,
  .mantine-MonthsList-monthsList,
  .mantine-DatePicker-monthsListCell {
    width: 100%;
    height: 100%;
  }
  .mantine-YearLevelGroup-yearLevelGroup {
    width: 100%;
    height: 90%;
  }

  .mantine-MonthsList-monthsListRow {
    display: flex;
    width: 100%;
    height: 25%;
    justify-content: space-around;
    flex-direction: row;
    align-items: stretch;
  }
  .mantine-PickerControl-pickerControl {
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
  }
`;

export default DateRangePicker;
