import BaseAccordion from "@/components/base/BaseAccordion";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseTable } from "@/components/base/table/BaseTable";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useWorkDataStore } from "@/store/work.store";
import { Accordion } from "@mantine/core";
import { t } from "i18next";

const WorkCurrentTempDefect = Object.assign({}, {
    Title: BaseTitle,
    Table: BaseTable,
    Accordion: BaseAccordion
})


export type DefectObject = {
    [key: string]: DefectObjectProps
};

export interface DefectObjectProps {
    defectQuantity: string;
    reworkQuantity?: string;
    actualDefectQuantity?: string;
    defectName?: string;
    defectCode?: string;
}

export const WorkCurrentTempDefectComponent = () => {

    const { tempWork } = useLocalStorage();

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));

    const { work } = workData

    const currentTempDefectList = Object.keys(tempWork?.defects ? tempWork?.defects as DefectObject : {}).map((code) => {
        return {
            defectName: (tempWork?.defects as DefectObject)[code]?.defectName as string,
            defectCode: code,
            defectQuantity: (tempWork?.defects as DefectObject)[code]?.defectQuantity as string,
            setUnitText: work?.item?.unitText
        }
    })

    return (
        <WorkCurrentTempDefect.Accordion>
            <Accordion.Item value="currentTempDefectTable">
                <Accordion.Control>
                    <WorkCurrentTempDefect.Title
                        fz={"1.625rem"}
                        c={"#FFFFFF"}
                    >
                        {t("임시 로트 불량기록")}
                    </WorkCurrentTempDefect.Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <WorkCurrentTempDefect.Table
                        columns={[t("불량명"), t("보고수량")]}
                        flex={[2, 1]}
                        data={currentTempDefectList?.map((defect) => {
                            return defect?.defectQuantity !== '0' ? [
                                defect?.defectName ?? "-",
                                <div style={{ textAlign: 'right' }}>{(defect?.defectQuantity ?? "-") + " " + defect?.setUnitText}</div>,
                            ] : [];
                        }) ?? [[]]}
                    />
                </Accordion.Panel>
            </Accordion.Item>
        </WorkCurrentTempDefect.Accordion>
    )
}