import { DEFECT_QUERY_KEY } from "@/api/sites/useSitesGetQuery";
import { MasterInstance } from "@/instance/axios";
import {
  MasterApiSitesPostRequest,
  MasterApiSitesSiteIdDeleteRequest,
  MasterApiSitesSiteIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";


interface CreateParams extends MasterApiSitesPostRequest { }
interface UpdateParams extends MasterApiSitesSiteIdPutRequest { }
interface DeleteParams extends MasterApiSitesSiteIdDeleteRequest { }

type MutationType = "create" | "update" | "delete";

type Params<T extends MutationType> = T extends "create"
  ? CreateParams
  : T extends "update"
  ? UpdateParams
  : DeleteParams;

const createFetcher = (params: CreateParams) =>
  MasterInstance.sitesPost({
    sitesGetRequest: params.sitesGetRequest,
  });

const updateFetcher = (params: UpdateParams) =>
  MasterInstance.sitesSiteIdPut(params);

const deleteFetcher = (params: DeleteParams) =>
  MasterInstance.sitesSiteIdDelete(params);

const useSitesMutationQuery = <T extends MutationType>(type: T) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (params: Params<typeof type>) => createFetcher(params as CreateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DEFECT_QUERY_KEY);
      },
    }
  );

  const updateMutation = useMutation(
    (params: Params<typeof type>) => updateFetcher(params as UpdateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DEFECT_QUERY_KEY);
      },
    }
  );

  const deleteMutation = useMutation(
    (params: Params<typeof type>) => deleteFetcher(params as DeleteParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DEFECT_QUERY_KEY);
      },
    }
  );

  if (type === "create") {
    return createMutation;
  } else if (type === "update") {
    return updateMutation;
  } else if (type === "delete") {
    return deleteMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useSitesMutationQuery;
