import { useEquipmentStore } from "@/store/equipment.store"
import { useSocketStore } from "@/store/socket.store"
import { useWorkDataStore } from "@/store/work.store"
import { useCallback, useEffect, useRef } from "react"

const useSocket = () => {
  const { socket, initializeSocket, joinRoom, leaveRoom, setData } = useSocketStore((state) => ({
    socket: state.socket,
    initializeSocket: state.initializeSocket,
    joinRoom: state.joinRoom,
    leaveRoom: state.leaveRoom,
    setData: state.setData,
  }))

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }))
  const { equipment, updateCurrentCount } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
    updateCurrentCount: state.updateCurrentCount,
  }))

  const { work } = workData
  const disabledSocket = work?.externalCounterSetting === 'HANDWRITING'
  const previousRoomRef = useRef<string | null>(null)

  useEffect(() => {
    if (!socket) {
      initializeSocket()
    }
  }, [socket])

  useEffect(() => {
    if (disabledSocket && previousRoomRef.current) {
      // Leave the room and clear the previous room reference when disabledSocket is true
      leaveRoom(previousRoomRef.current)
      previousRoomRef.current = null
      return
    }

    if (socket && equipment?.code) {
      const newRoom = `EC_${equipment.code}`

      if (previousRoomRef.current && previousRoomRef.current !== newRoom) {
        leaveRoom(previousRoomRef.current)
      }

      joinRoom(newRoom)
      previousRoomRef.current = newRoom
    } else if (socket && previousRoomRef.current) {
      leaveRoom(previousRoomRef.current)
      previousRoomRef.current = null
    }
  }, [socket, equipment?.code, disabledSocket])

  const handleEvent = useCallback(
    (message: { equipmentCounter: string; equipmentLimitCounter: string; isLimitHit: boolean }) => {
      setData(JSON.stringify(message))
      updateCurrentCount({
        currentCount: message.equipmentCounter,
        limitCount: message.equipmentLimitCounter,
        isLimitHit: message.isLimitHit,
      })
    },
    [setData, updateCurrentCount]
  )

  useEffect(() => {
    if (!socket || !previousRoomRef.current || disabledSocket) return

    socket.on(previousRoomRef.current, handleEvent)

    return () => {
      if (previousRoomRef.current) {
        socket.off(previousRoomRef.current, handleEvent)
      }
    }
  }, [socket, previousRoomRef.current, handleEvent, disabledSocket])
}

export default useSocket
