// src/layouts/common/BodyLayout.js
import { Flex } from '@mantine/core';
import { ReactNode } from 'react';

interface BodyLayoutProps {
    children: ReactNode;
}

const BaseBody = ({ children }: BodyLayoutProps) => {
    return (
        <Flex
            gap={'0.625rem'}
            p={'0.625rem'}
            direction={'column'}
            align={'flex-start'}>
            {children}
        </Flex>
    );
};

export default BaseBody;