import { BaseTextInput } from "@/test/components/atom/textInput/BaseInput";
import { TextInputProps } from "@mantine/core";

interface FormTextInputProps extends TextInputProps { }

export const FormTextInput = ({ ...etcProps }: FormTextInputProps) => {

    return (
        <BaseTextInput
            size="lg"
            {...etcProps}
        />
    )

}