import {
  INVENTORIES_KEY,
  mutateInventories,
} from "@/api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "@/api/logs/useWorksLogsQuery";
import { useModal } from "@/context/ModalStackManager";

import { useWorkDataStore } from "@/store/work.store";
import { COMMON_TEXT } from "@/test/constants/text";
import { customNotification } from "@/utils/notificationShow";
import {
  ProductionActionApiWorksWorkIdInputPutRequest,
  WorksWorkIdInputPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

export const useRawMaterialHandler = () => {
  const { closeModal } = useModal();

  const queryClient = useQueryClient();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { mutate: InventoriesMutate } = useMutation(
    (params: ProductionActionApiWorksWorkIdInputPutRequest) =>
      mutateInventories.workInput(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INVENTORIES_KEY);
        queryClient.invalidateQueries(WORK_LOGS_KEY);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error.response?.data.message ??
            COMMON_TEXT.NOTIFICATION.RAW_MATERIAL_INPUT_FAIL,
        });
      },
    }
  );

  const onSubmit = ({
    worksWorkIdInputPutRequest,
  }: {
    worksWorkIdInputPutRequest: WorksWorkIdInputPutRequest;
  }) => {
    try {
      InventoriesMutate(
        {
          workId: work?.id as number,
          worksWorkIdInputPutRequest: worksWorkIdInputPutRequest,
        },
        {
          onSuccess: () => {
            customNotification.success({
              message: COMMON_TEXT.NOTIFICATION.RAW_MATERIAL_INPUT_SUCCESS,
            });
            // onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
            closeModal({});
          },
        }
      );
    } catch (e) {
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.RAW_MATERIAL_INPUT_FAIL,
      });
    }
  };

  return { onSubmit };
};
