import DefaultInstance from "@/instance/axios";
import { DefaultApiWorkLogsGetRequest, WorkLogsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export const WORK_LOG_GET_KEY = "work_log";

const fetchWorkLogsGet = (params: DefaultApiWorkLogsGetRequest) => {

  const query: any = {};

  // query를 보내는 조건은 필터 검색을 할 때이다.
  if (params?.query?.$and?.length > 0) {
    query["query"] = JSON.stringify(params?.query);
  }
  // 검색내용이 있을 때만 search를 보내야 한다.
  if (params.search) {
    query["search"] = params.search;
    query["searchFields"] = params.searchFields;
  }

  return DefaultInstance.workLogsGet({
    ...query,
    page: params.page ?? 1,
    pageSize: 10,
    populate: ["itemUnit", "defectName", "creatorUser", "alreadyCanceledWorkLog"],
    sort: params.sort,
  });
};

export const useWorkLogsGet = (
  params: DefaultApiWorkLogsGetRequest,
  options?: {
    onSuccess: (work: WorkLogsGet200Response) => void;
  }
) => {
  return useQuery(
    [WORK_LOG_GET_KEY, params],
    () => fetchWorkLogsGet(params),
    {
      select: (data: AxiosResponse<WorkLogsGet200Response, any>) => data.data,
      onSuccess: options?.onSuccess,
    }
  );
};
