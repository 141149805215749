import { COMMON_TEXT } from "@/test/constants/text";
import { ActionIcon, Box, Text } from "@mantine/core";
import { IconReload, IconTrash } from "@tabler/icons-react";

interface TableActionBoxProps {
    row?: any,
    onClickDelete?: (data: any) => void;
    onClickCancel?: (data: any) => void;
}
export const TableActionBox = ({ row, onClickDelete, onClickCancel }: TableActionBoxProps) => {
    return (
        <Box sx={{ display: 'flex', gap: 'md' }}>
            {onClickCancel ? (
                <ActionIcon
                    sx={{ display: 'flex', width: '100%' }}
                    color="red"
                    onClick={() => onClickCancel(row)}
                >
                    {row?.original.alreadyCanceledWorkLog
                        ? <Text color="purple">{COMMON_TEXT.ACTION.CANCELLED}</Text> :
                        <>
                            <IconReload />
                            <Text size={'sm'}>{COMMON_TEXT.ACTION.CANCEL}</Text>
                        </>}
                </ActionIcon>
            ) : (
                <ActionIcon
                    sx={{ display: 'flex', width: '100%' }}
                    color="red"
                    onClick={() => onClickDelete && onClickDelete(row)}
                >
                    <IconTrash />
                    <Text size={'sm'}>삭제</Text>
                </ActionIcon>
            )}
        </Box>
    )

}