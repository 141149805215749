import { useLocalStorage } from "@/context/LocalStorageProvider";
import { INVENTORIES_KEY } from "@/test/apis/inventories/useInventoriesQuery";
import { usersMutate } from "@/test/apis/users/useUserQuery";
import { WORK_LOGS_KEY } from "@/test/apis/workLogs/useWorkLogsQuery";
import { COMMON_TEXT } from "@/test/constants/text";
import { customNotification } from "@/utils/notificationShow";
import { MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUserHandler = () => {
  const queryClient = useQueryClient();
  const { setEquipmentCode, setWorkId, setLocationCode, setPrintCode } =
    useLocalStorage();

  const { mutate: InventoriesMutate } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      usersMutate.update(params).mutationFn(undefined),
    {
      onSuccess: (data) => {
        // 성공 메시지 표시
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.USER_MODIFY,
        });

        // 쿼리 무효화
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
    }
  );

  const onSubmit = ({
    masterApiUsersUserIdPutRequest,
    successCallback,
  }: {
    masterApiUsersUserIdPutRequest: MasterApiUsersUserIdPutRequest;
    successCallback?: (data: any) => void;
  }) => {
    InventoriesMutate(masterApiUsersUserIdPutRequest, {
      onSuccess: (data) => {
        if (successCallback) {
          successCallback(data);
        }
      },
    });
  };

  return { onSubmit };
};
