import { LocationAutoComplete } from "@/components/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/components/autoComplete/sites/sites-autoComplete";
import { Box, Button, Code, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export function RoutingOperationForm(params: {
  code: string;
  onSubmit?: (values: any) => void;
}) {
  const { code, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name: null, // 라우팅경로 명
      code: code, // 라우팅경로 코드
      siteCode: null, // 사업장 코드
      fromLocationCode: null, // 자재 출고 로케이션
      toLocationCode: null, // 제공품 입고 로케이션
    },
  });

  return (
    <Box maw={320} mx="auto" w={400}>
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          label="라우팅경로 코드"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        <TextInput
          label="라우팅경로 이름"
          placeholder="name"
          {...form.getInputProps("name")}
        />
        <SitesAutoComplete
          label="사업장 코드"
          placeholder="siteCode"
          width={"100%"}
          {...form.getInputProps("siteCode")}
        />
        <LocationAutoComplete
          label="자재 출고 로케이션"
          width={"100%"}
          {...form.getInputProps("fromLocationCode")}
        />
        <LocationAutoComplete
          label="제공품 입고 로케이션"
          width={"100%"}
          {...form.getInputProps("toLocationCode")}
        />
        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button
          type="submit"
          disabled={
            !form.values.code ||
            !form.values.siteCode ||
            !form.values.fromLocationCode ||
            !form.values.toLocationCode
          }
        >
          Submit
        </Button>
      </form>
    </Box>
  );
}
