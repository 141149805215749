import BaseAccordion from "@/components/base/BaseAccordion";
import BaseProgress from "@/components/base/BaseProgress";
import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseTable } from "@/components/base/table/BaseTable";
import { useWorkDataStore } from "@/store/work.store";
import { theme } from "@/styles/theme";
import { Accordion, Flex } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { t } from "i18next";

const WorkLocation = Object.assign({}, {
    Title: BaseTitle,
    Text: BaseText,
    Table: BaseTable,
    Progress: BaseProgress,
    Accordion: BaseAccordion
})

export const WorkLocationComponent = () => {

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { work } = workData

    return (
        <Accordion
            w={"100%"}
            chevron={<IconPlus size="2.5rem" />}
            styles={
                {
                    item: { border: `1px solid ${theme?.colors?.gray?.[7]}` },
                    control: { ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` } },
                    chevron: {
                        color: "white",
                        '&[data-rotate]': {
                            transform: "rotate(45deg)"
                        },
                    }
                }
            }
        >
            <Accordion.Item value="ledgerTable">
                <Accordion.Control>
                    <Flex w={"100%"} justify={"space-between"}>
                        <WorkLocation.Title
                            fz={"1.625rem"}
                            c={"#FFFFFF"}
                        >
                            {t("로케이션")}
                        </WorkLocation.Title>
                    </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                    <Flex w={"100%"} direction={'column'} p={10} gap={10}>
                        <Flex justify={"space-between"}>
                            <WorkLocation.Title>{t("원부자재 로케이션")}</WorkLocation.Title>
                            <Flex wrap={"wrap"}>
                                <WorkLocation.Text>
                                    [{work?.currentRoutingOutsourceData?.fromLocationCode ?? work?.locationSetting?.fromLocation?.code}]
                                </WorkLocation.Text>
                                <WorkLocation.Text>
                                    {work?.currentRoutingOutsourceData?.fromLocationName ?? work?.locationSetting?.fromLocation?.name}
                                </WorkLocation.Text>
                            </Flex>
                        </Flex>
                        <Flex justify={"space-between"}>
                            <WorkLocation.Title>{t("재공품 입고 로케이션")}</WorkLocation.Title>
                            <Flex wrap={"wrap"}>
                                <WorkLocation.Text>
                                    [{work?.currentRoutingOutsourceData?.toLocationCode ?? work?.locationSetting?.toLocation?.code}]
                                </WorkLocation.Text>
                                <WorkLocation.Text>
                                    {work?.currentRoutingOutsourceData?.toLocationName ?? work?.locationSetting?.toLocation?.name}
                                </WorkLocation.Text>
                            </Flex>
                        </Flex>
                        <Flex justify={"space-between"}>
                            <WorkLocation.Title>{t("생산 로케이션")}</WorkLocation.Title>
                            <Flex wrap={"wrap"}>
                                <WorkLocation.Text>
                                    [{work?.locationSetting?.targetLocation?.code ?? '-'}]
                                </WorkLocation.Text>
                                <WorkLocation.Text>
                                    {work?.locationSetting?.targetLocation?.name ?? '-'}
                                </WorkLocation.Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    )
}