import { GET_INSPEC_QUERY_KEY } from "@/api/inspections/useInspectionsGetQuery";
import { GET_SPC_QUERY_KEY } from "@/api/inspections/useInspectionsInspectionGetQuery";
import { SpcInstance } from "@/instance/axios";
import {
  InspectionsGetRequest,
  SpcApiInspectionsInspectionIdPutRequest,
  SpcApiInspectionsInspectionIdRecordPutRequest,
  SpcApiInspectionsInspectionIdSubmitPostRequest,
  SpcApiInspectionsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";



interface SubmitParams extends SpcApiInspectionsInspectionIdSubmitPostRequest { }
interface RecordParams extends SpcApiInspectionsInspectionIdRecordPutRequest { }
interface CreateParams extends SpcApiInspectionsPostRequest { }
interface UpdateParams extends SpcApiInspectionsInspectionIdPutRequest {
  inspectionsGetRequest: InspectionsGetRequest & {
    specificationData: any;
    record: any;
  };
}

type MutationType = "submit" | "record" | "create" | "update";

type Params<T extends MutationType> = T extends "submit"
  ? SubmitParams
  : T extends "record"
  ? RecordParams
  : T extends "update"
  ? UpdateParams
  : CreateParams;

const submitFetcher = (params: SubmitParams) =>
  SpcInstance.inspectionsInspectionIdSubmitPost(params);

const recordFetcher = (params: RecordParams) =>
  SpcInstance.inspectionsInspectionIdRecordPut(params);

const createFetcher = (params: CreateParams) =>
  SpcInstance.inspectionsPost({
    inspectionsGetRequest: params.inspectionsGetRequest,
  });

const updateFetcher = (params: UpdateParams) =>
  SpcInstance.inspectionsInspectionIdPut({
    inspectionId: params.inspectionId,
    inspectionsGetRequest: params.inspectionsGetRequest,
  });

const useInspectionMutation = <T extends MutationType>(type: T) => {
  const queryClient = useQueryClient();

  const submitMutation = useMutation(
    (params: Params<typeof type>) => submitFetcher(params as SubmitParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SPC_QUERY_KEY);
        queryClient.invalidateQueries(GET_INSPEC_QUERY_KEY);
      },
    }
  );

  const recordMutation = useMutation(
    (params: Params<typeof type>) => recordFetcher(params as RecordParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SPC_QUERY_KEY);
        queryClient.invalidateQueries(GET_INSPEC_QUERY_KEY);
      },
    }
  );

  const createMutation = useMutation(
    (params: Params<typeof type>) => createFetcher(params as CreateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SPC_QUERY_KEY);
        queryClient.invalidateQueries(GET_INSPEC_QUERY_KEY);
      },
    }
  );

  const updateMutation = useMutation(
    (params: Params<typeof type>) => updateFetcher(params as UpdateParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SPC_QUERY_KEY);
        queryClient.invalidateQueries(GET_INSPEC_QUERY_KEY);
      },
    }
  );

  if (type === "submit") {
    return submitMutation;
  } else if (type === "record") {
    return recordMutation;
  } else if (type === "create") {
    return createMutation;
  } else if (type === "update") {
    return updateMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useInspectionMutation;
