import DefaultInstance from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import { DefaultApiWmsTransformPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const TRANSFORM_KEY = 'transform'


export const mutateTransform = createMutationKeys(TRANSFORM_KEY, {
    transform: (params: DefaultApiWmsTransformPostRequest) => {
        return {
            mutationKey: [params.wmsTransformPostRequest],
            mutationFn: () => DefaultInstance.wmsTransformPost(params)
        }
    }
});