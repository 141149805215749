import { Button, ButtonProps } from "@mantine/core";

export interface ChangeNumberButtonProps
  extends Partial<ButtonProps>,
  Partial<React.RefAttributes<HTMLButtonElement>> {
  num: number;
  onChange?: (e: any) => void;
  value?: string;
  width?: string;
  btnType?: 'increase' | 'decrease';
}

export const ChangeNumberButton = (props: ChangeNumberButtonProps) => {
  const { num, onChange, btnType = 'increase', ...etcParams } = props;

  const onClick = () => {
    btnType === 'increase'
      ? onChange && onChange(Number(etcParams?.value) + num)
      : onChange && onChange(Number(etcParams?.value) - num);
  };

  return (
    <Button onClick={onClick} {...etcParams}>
      {btnType === 'increase' ? '+' : '-'}{num}
    </Button>
  );
};

export default ChangeNumberButton;


