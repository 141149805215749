import { MasterInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiItemsFindPostRequest, MasterApiItemsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const ITEMS_KEY = 'items';

export const items = createQueryKeys(ITEMS_KEY, {
    all: null,
    get: (params: MasterApiItemsGetRequest, option?: void) => {
        const query = { ...params };
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.itemsGet(query),
        }
    },
    find: (params: MasterApiItemsFindPostRequest) => {

        return {
            queryKey: [params],
            queryFn: () => MasterInstance.itemsFindPost(params),
        }
    },
    codeSearch: (params?: MasterApiItemsGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        if (params && params.query) {
            query["query"] = JSON.stringify(params.query);
        }

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.itemsGet(query),
        };
    },
});