import { BaseButton } from "@/components/base/button/BaseButton";
import { WorkStatusButton } from "@/components/button/workStatusButton";
import { DownTimeReasonsForm } from "@/components/form/downtimeReasons/DownTimeReasonsForm";
import { InputLogsForm } from "@/components/form/inputLogs/inputLogsForm";
import { InspectForm } from "@/components/form/inspect/InspectForm";
import { LogsForm } from "@/components/form/logs/logsForm";
import { RawMaterialForm } from "@/components/form/material/RawMaterialForm";
import { RecycleForm } from "@/components/form/recycle/RecycleForm";
import { customFunctions } from "@/config/customFunctions";

import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import useConfirmModal from "@/hooks/useConfirmModal.hook";
import { usePerformanceActions } from "@/hooks/usePerformanceActions.hook";
import useWorkActions from "@/hooks/useWorkActions.hook";

import i18n from "@/language/i18n";
import { useWorkDataStore } from "@/store/work.store";
import { RawMaterialAllForm } from "@/test/components/organism/form/RawMaterialAllForm";

import { Flex } from "@mantine/core";
import {
  IconArticle,
  IconClipboardCheck,
  IconPlus,
  IconRecycle,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const ActionHeader = Object.assign(
  {},
  {
    Button: BaseButton,
    StatusButton: WorkStatusButton,
  }
);

export const WorkActionHeader = () => {
  const { t } = useTranslation();
  const { setRoutingCode } = useLocalStorage();
  const { openModal, closeModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work, lots, inventories, inputLogsAll } = workData;

  const {
    confirmProductionRecordEnteredModal,
    confirmPreviousMaterialUsedModal,
    confirmWorkClosureModal,
  } = useConfirmModal();
  const { handlePreviousOrderClosure, handleFlush } = usePerformanceActions();
  const { onCorrect, onStart, onResume, onEnd, onPause } = useWorkActions({
    workData: work,
  });

  // 조건에 사용되는 값
  const isLastLogInputType = inputLogsAll?.[0]?.workLogType === "INPUT";
  const isLastLogCanceled = inputLogsAll?.[0]?.alreadyCanceledWorkLog;
  const isInventory = (inventories?.rows?.length as number) > 0;
  const isPerformanceRecordedAfterInput =
    !isLastLogCanceled && isLastLogInputType;

  // 투입 이후 실적을 입력했는지에 대한 판단 하는 함수 및 모달
  const showProductionRecord = () => {
    if (isPerformanceRecordedAfterInput) {
      return confirmProductionRecordEnteredModal();
    }
    return true;
  };

  // 이전 자재 사용 여부 확인 모달
  const showPreviousMaterialUsed = () => {
    const isCorrect = isInventory && !isPerformanceRecordedAfterInput;
    if (isCorrect) {
      return confirmPreviousMaterialUsedModal();
    }
    return false;
  };

  // 원부자재 투입 모달
  const showRawMaterial = () => {
    return openModal(<RawMaterialForm />, "", i18n.t("원부자재 투입"));
  };

  // 원부자재 일괄투입 모달
  const showRawMaterialAll = () => {
    return openModal(<RawMaterialAllForm />, "", i18n.t("원부자재 투입"));
  };

  // 원부자재 투입 이벤트
  const handleInputRawMaterial = async () => {
    if (customFunctions.ADD_PRODUCTION_RECORD_ENTERED) {
      const productionRecord = await showProductionRecord();
      if (!productionRecord) return;
    }

    if (customFunctions.ADD_INVENTORY_CORRECT) {
      const previousMaterial = await showPreviousMaterialUsed();
      if (previousMaterial) {
        onCorrect();
      }
    }

    showRawMaterial();
  };

  // 원부자재 일괄투입
  const handleInputRawMaterialAll = async () => {
    if (customFunctions.ADD_PRODUCTION_RECORD_ENTERED) {
      const productionRecord = await showProductionRecord();
      if (!productionRecord) return;
    }

    if (customFunctions.ADD_INVENTORY_CORRECT) {
      const previousMaterial = await showPreviousMaterialUsed();
      if (previousMaterial) {
        onCorrect();
      }
    }

    showRawMaterialAll();
  };

  // 작업시작 이벤트
  const handleStart = async () => {
    const startWork = async () => {
      onStart();
      setRoutingCode(work?.routingCode);
      closeModal({});
    };

    // 이전 작업지시서가 종료 되었는지를 묻는다.
    const PreviousOrderClosure = await handlePreviousOrderClosure();
    if (!PreviousOrderClosure) return;

    // 플러싱 여부 묻는다.
    await handleFlush();
    await startWork();
  };

  // 작업재개 이벤트
  const handleResume = async () => {
    const resumeWork = async () => {
      await setRoutingCode(work?.routingCode);
      await onResume();
    };

    // 이전 작업지시서가 종료 되었는지를 묻는다.
    const PreviousOrderClosure = await handlePreviousOrderClosure();

    if (PreviousOrderClosure) {
      await resumeWork();
    }
  };

  // 작업종료 이벤트
  const handleEnd = async () => {
    const confirmWorkClosure = await confirmWorkClosureModal();
    if (confirmWorkClosure) {
      await onEnd();
    }
  };

  // 일시정지 이벤트
  const handlePause = () => {
    openModal(
      <DownTimeReasonsForm
        onClick={(equipmentCode: string) => {
          onPause(equipmentCode);
          closeModal(undefined);
        }}
        workData={work}
      />,
      "",
      i18n.t("작업 일시정지"),
      true
    );
  };

  return (
    <>
      <Flex gap={"0.625rem"} justify={"flex-start"}>
        <ActionHeader.Button
          key={"input"}
          onClick={handleInputRawMaterial}
          color={"green.6"}
          leftIcon={<IconPlus size={"2rem"} />}
        >
          {t("원부자재 투입")}
        </ActionHeader.Button>
        <ActionHeader.Button
          key={"inputAll"}
          onClick={handleInputRawMaterialAll}
          color={"teal.6"}
          leftIcon={<IconPlus size={"2rem"} />}
        >
          {t("원부자재 일괄 투입")}
        </ActionHeader.Button>
        <ActionHeader.Button
          key={"log"}
          onClick={() =>
            openModal(<InputLogsForm />, "", i18n.t("투입 기록 확인"))
          }
          color={"yellow.6"}
          leftIcon={<IconClipboardCheck size={"2rem"} />}
        >
          {t("투입 기록")}
        </ActionHeader.Button>
        <ActionHeader.Button
          key={"inspection"}
          onClick={() =>
            openModal(
              <InspectForm workData={work} lotId={lots?.id} />,
              "",
              i18n.t("검사 기록 보고")
            )
          }
          color={"blue.6"}
          leftIcon={<IconClipboardCheck size={"2rem"} />}
        >
          {t("초중종품 검사")}
        </ActionHeader.Button>
        {customFunctions.ADD_RECYCLE_DEFECT && (
          <ActionHeader.Button
            key={"inspection"}
            onClick={() =>
              openModal(<RecycleForm />, "", i18n.t("검사 기록 보고"))
            }
            color={"green.8"}
            leftIcon={<IconRecycle size={"2rem"} />}
          >
            {t("불량 재활용")}
          </ActionHeader.Button>
        )}
      </Flex>
      <Flex gap={"0.625rem"} justify={"flex-end"}>
        <ActionHeader.Button
          key={"workLogs"}
          color={"cyan.6"}
          leftIcon={<IconArticle size={"2rem"} />}
          onClick={() => openModal(<LogsForm />, "", i18n.t("작업 로그 확인"))}
        >
          {t("작업로그")}
        </ActionHeader.Button>
        <ActionHeader.StatusButton
          externalCounterSetting={work?.externalCounterSetting}
          trackingStatus={work?.trackingStatus}
          handleStart={handleStart}
          handleEnd={handleEnd}
          handlePause={handlePause}
          handleResume={handleResume}
        />
        <ActionHeader.StatusButton />
      </Flex>
    </>
  );
};
