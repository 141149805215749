import { SpcInstance } from "@/instance/axios";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export const GET_SPC_QUERY_KEY = "get-spc-inspections-inspection";

const getSpcInspectionsInspectionFetch = (params?: any) => {
  return SpcInstance.inspectionsInspectionIdGet({
    inspectionId: params?.inspectionId,
  });
};

const useSpcInspectionsInspectionGetQuery = (
  params?: {
    inspectionId: number | undefined;
  },
  options?: {
    onSuccess: (data: any) => void;
  }
) => {
  return useQuery<AxiosResponse<any, any[]>>({
    queryKey: [GET_SPC_QUERY_KEY],
    enabled: !!params?.inspectionId,
    queryFn: () =>
      params
        ? getSpcInspectionsInspectionFetch(params)
        : getSpcInspectionsInspectionFetch(),
  });
};

export default useSpcInspectionsInspectionGetQuery;
