import DefaultInstance from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerLotsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export type LotOption = {
    value: string;
    label: string;
    // expirationLabel : string;
    group: string;
};

const getAutoCompleteKeyword = (keyword: string) =>
    DefaultInstance.lotsFindPost({
        search: keyword ? keyword : "",
        searchFields: ["name"],
    }).then(
        (res: AxiosResponse<ItemsGet200ResponseRowsInnerLotsInner[]>) => res.data
    );
export const useLotsAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: LotOption[]) => void;
    }
) => {
    return useQuery(
        ["Lots-get-autocomplete", keyword],
        () => getAutoCompleteKeyword(keyword),
        {
            enabled: focus,
            select: (data: ItemsGet200ResponseRowsInnerLotsInner[]) =>
                data?.map((Lot) => {
                    return {
                        value: String(Lot.id),
                        label: String(Lot.name),
                        // expirationLabel : String(Lot.expiration),
                        group: String(Lot.itemCode),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getLotsByCode = () =>
    DefaultInstance.lotsFindPost({}).then(
        (res: AxiosResponse<ItemsGet200ResponseRowsInnerLotsInner[]>) => res.data
    );

export const useLotsCode = (
    enabled: boolean,
    options?: {
        onSuccess: (data?: LotOption[]) => void;
    }
) => {
    return useQuery(["Lots-get-code"], () => getLotsByCode(), {
        enabled: enabled ?? false,
        select: (data: ItemsGet200ResponseRowsInnerLotsInner[]) =>
            data?.map((Lot) => {
                return {
                    value: String(Lot.id),
                    label: String(Lot.name),
                    //   expirationLabel: String(Lot.expiration),
                    group: String(Lot.itemCode),
                };
            }) ?? [],
        onSuccess: options?.onSuccess,
    });
};
