import { DefectAutoComplete } from "@/components/autoComplete/defect/DefectAutoComplete";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseButton } from "@/components/base/button/BaseButton";
import { BoxNumberInput } from "@/components/number/boxNumberInput";
import ChangeNumberButton from "@/components/number/changeNumberButton";
import { DefectObject, DefectObjectProps } from "@/components/section/WorkCurrentDefectComponent";
import { customFunctions } from "@/config/customFunctions";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import useWorkActions from "@/hooks/useWorkActions.hook";
import { useWorkDataStore } from "@/store/work.store";
import { plusBigNumber } from "@/utils/calculate";
import { customNotification } from "@/utils/notificationShow";
import { Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconTrashOff } from "@tabler/icons-react";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { useTranslation } from "react-i18next";



const WorkDefectReport = Object.assign({}, {
    Title: BaseTitle,
    Button: BaseButton,
    AutoComplete: DefectAutoComplete,
    Input: BoxNumberInput,
    ChangeNumberButton: ChangeNumberButton,
});

export const WorkDefectReportComponent = () => {

    const { tempWork, setTempWork } = useLocalStorage();
    const [defectCode, setDefectCode] = useState<string | null>("");

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { work } = workData
    const { onDefect } = useWorkActions({ workData: work })
    const { t } = useTranslation()

    const form = useForm({
        initialValues: {
            defects: {} as any,
        },
    });

    // 임시 불량 저장 
    const handleTempDefectSave = (data: DefectObjectProps) => {
        setTempWork({
            ...tempWork,
            defects: {
                ...tempWork.defects,
                [data.defectCode as string]: {
                    ...tempWork.defects?.[data.defectCode || ""],
                    defectName: data?.defectName,
                    defectQuantity: plusBigNumber((tempWork.defects?.[data.defectCode || ""]?.defectQuantity ?? 0), data?.defectQuantity)
                }
            }
        })
    }

    const handleDefectSave = (data: DefectObjectProps) => {

        const isDefectQuantityGreaterThanEnd = BigNumber(data.defectQuantity)
            .isGreaterThan(work?.summary?.end as string);
        const isDefectQuantityGreaterThanEndDetailMessage = t("불량 수량이 생산수량보다 많습니다.")

        if (isDefectQuantityGreaterThanEnd) {
            return customNotification.error({ message: isDefectQuantityGreaterThanEndDetailMessage })
        }

        onDefect(
            {
                defectCode: data.defectCode as string,
                quantity: data.defectQuantity
            },
        );
    }

    return (
        <>
            <Flex w={'100%'} justify={'space-between'}>
                <WorkDefectReport.Title fz={"1.625rem"} c={"#FFFFFF"}>{t('불량 보고')}</WorkDefectReport.Title>
                <WorkDefectReport.Button
                    size="md"
                    disabled={!defectCode}
                    onClick={() => {
                        customFunctions.ADD_TEMP_FUNCTION_ENABLED ?
                            handleTempDefectSave({
                                ...form.values.defects[defectCode as string],
                                defectCode: defectCode as string
                            })
                            :
                            handleDefectSave({
                                ...form.values.defects[defectCode as string],
                                defectCode: defectCode as string
                            });
                        setDefectCode(null);
                    }}
                    type={"submit"}
                    bg={'red.6'}
                    rightIcon={<IconTrashOff size={'2rem'} />}
                >{t('불량 보고')}</WorkDefectReport.Button>
            </Flex>
            <Flex gap={10}>
                <WorkDefectReport.AutoComplete
                    size="lg"
                    radius="xs"
                    defectOperationCode={work?.routingData?.operationCode}
                    value={defectCode}
                    onChange={(defectCode: string | null) => {
                        if (defectCode !== null) {
                            form.setValues({
                                defects: {
                                    ...form.values.defects,
                                    [defectCode as string]: {
                                        ...form.values.defects?.[defectCode || ""],
                                        defectName: (work?.summary?.defect as DefectObject)?.[defectCode as string]?.defectName,
                                        defectQuantity: '0'
                                    }
                                }
                            })
                        }
                        setDefectCode(defectCode)
                    }}
                />
                <WorkDefectReport.ChangeNumberButton
                    btnType="decrease"
                    size="lg"
                    color="grape.8"
                    num={5}
                    disabled={!defectCode}
                    {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
                />
                <WorkDefectReport.ChangeNumberButton
                    btnType="decrease"
                    size="lg"
                    color="grape.6"
                    num={1}
                    disabled={!defectCode}
                    {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
                />
                <WorkDefectReport.Input
                    size="lg"
                    disabled={!defectCode}
                    {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
                    value={Number(form.values.defects?.[defectCode || ""]?.defectQuantity)}
                />
                <WorkDefectReport.ChangeNumberButton
                    size="lg"
                    color="cyan.6"
                    num={1}
                    disabled={!defectCode}
                    {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
                />
                <WorkDefectReport.ChangeNumberButton
                    size="lg"
                    color="indigo.6"
                    num={5}
                    disabled={!defectCode}
                    {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
                />
            </Flex>
        </>
    );
};