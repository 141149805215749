import { BaseHeader } from "@/components/base/BaseHeader";
import { BaseSection } from "@/components/base/BaseSection";
import BaseBody from "@/components/base/body";
import { BaseButton } from "@/components/base/button/BaseButton";
import { LeftContent } from "@/components/base/content/leftContent";
import { MainContent } from "@/components/base/content/mainContent";
import { RightContent } from "@/components/base/content/rightContent";
import { WorkStatusButton } from "@/components/button/workStatusButton";
import { WorkActionHeader } from "@/components/header/WorkActionHeader";
import { WorkCurrentDefectInform } from "@/components/section/WorkCurrentDefectComponent";
import { WorkCurrentLotComponent } from "@/components/section/WorkCurrentLotComponent";
import { WorkCurrentTempDefectComponent } from "@/components/section/WorkCurrentTempDefectComponent";
import { WorkDefectReportComponent } from "@/components/section/WorkDefectReportComponent";
import { WorkEndReportComponent } from "@/components/section/WorkEndReportComponent";
import { WorkInformComponent } from "@/components/section/WorkInformComponent";
import { WorkInventoryInform } from "@/components/section/WorkInventoriesComponent";
import { WorkLocationComponent } from "@/components/section/WorkLocationComponent";
import { WorkProgressInformComponent } from "@/components/section/WorkProgressInformComponent";
import { WorkTimeTrackInform } from "@/components/section/WorkTimeTrackComponent";
import { customFunctions } from "@/config/customFunctions";
import { useGetWorkData } from "@/hooks/useGetWorkData.hook";
import { usePerformanceActions } from "@/hooks/usePerformanceActions.hook";
import useSocket from "@/hooks/useSocket.hook";
import { useEquipmentStore } from "@/store/equipment.store";
import { useWorkDataStore } from "@/store/work.store";
import { Divider } from "@mantine/core";
import { useEffect } from "react";

const Work = Object.assign(
  {},
  {
    Body: BaseBody,
    Button: BaseButton,
    StatusButton: WorkStatusButton,
    Header: BaseHeader,
    Content: MainContent,
    LeftContent: LeftContent,
    RightContent: RightContent,
    Section: BaseSection,
  }
);

export const WorkByRowPage = () => {
  const { handleLimitCounter } = usePerformanceActions();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const { equipment } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
  }));
  useGetWorkData();
  useSocket();

  useEffect(() => {
    const isLimitHit =
      equipment?.isLimitHit &&
      work?.externalCounterSetting === "COUNTER_AND_HANDWRITING";
    if (isLimitHit) {
      handleLimitCounter();
    }
  }, [equipment?.isLimitHit, work?.externalCounterSetting]);

  return (
    <Work.Body>
      <Work.Header>
        <WorkActionHeader />
      </Work.Header>
      <Work.Content>
        <Work.LeftContent>
          <Work.Section>
            <WorkInformComponent />
          </Work.Section>
          <Divider
            size={"md"}
            h={0}
            w={"100%"}
            variant="solid"
            color="#CED4DA"
          />
          <Work.Section>
            <WorkProgressInformComponent />
          </Work.Section>
          <Divider
            size={"md"}
            h={0}
            w={"100%"}
            variant="solid"
            color="#CED4DA"
          />
          {customFunctions.ADD_WORK_TIME_TRACKING_POSITION && (
            <Work.Section>
              <WorkTimeTrackInform />
            </Work.Section>
          )}
          <Work.Section>
            <WorkInventoryInform />
          </Work.Section>
          <Work.Section>
            <WorkLocationComponent />
          </Work.Section>
        </Work.LeftContent>

        <Work.RightContent>
          {!customFunctions.ADD_WORK_TIME_TRACKING_POSITION && (
            <Work.Section>
              <WorkTimeTrackInform />
            </Work.Section>
          )}
          {customFunctions.ADD_TEMP_FUNCTION_ENABLED && (
            <>
              <Work.Section>
                <WorkCurrentLotComponent />
              </Work.Section>
              <Divider
                size={"md"}
                h={0}
                w={"100%"}
                variant="solid"
                color="#CED4DA"
              />
            </>
          )}
          <Work.Section>
            <WorkCurrentDefectInform />
          </Work.Section>
          {customFunctions.ADD_TEMP_FUNCTION_ENABLED && (
            <>
              <Divider
                size={"md"}
                h={0}
                w={"100%"}
                variant="solid"
                color="#CED4DA"
              />
              <Work.Section>
                <WorkCurrentTempDefectComponent />
              </Work.Section>
            </>
          )}
          <Work.Section>
            <Work.Section gap={"xl"}>
              <WorkDefectReportComponent />
              <WorkEndReportComponent />
            </Work.Section>
          </Work.Section>
        </Work.RightContent>
      </Work.Content>
    </Work.Body>
  );
};
