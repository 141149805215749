import useSitesMutationQuery from "@/api/sites/useSitesMutationQuery";
import { SitesOption, useSiteCode, useSitesAutoComplete } from "@/components/autoComplete/sites/auto-useGetSitesQuery";
import { SiteForm } from "@/components/form/sites/Sitesform";
import { useModal } from "@/context/ModalStackManager";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { EventHandler, forwardRef, useState } from "react";

interface SitesProps extends React.ComponentPropsWithoutRef<"div"> {
  // group: string;
  label: string;
  value: string;
}

export interface SitesAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  width: string;
  value?: string | null;
  onChange?: (SitesCode: string | null) => void;
  maxDropdownHeight?: number;
}

// 입력값: value (Sites 모델의 code)
// 출력값: onChange (Sites 모델의 code)

export const SitesAutoComplete = (params: SitesAutoCompleteProps) => {
  const {
    width,
    value: SitesCode,
    onChange,
    maxDropdownHeight,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useSitesAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useSiteCode(!!SitesCode, SitesCode ?? null);

  let selectedSites = initialOptions?.find(
    (Sites) => Sites.value === SitesCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedSites = options?.find((Sites) => Sites.value === e);
    onChange && onChange(e);
  };

  const SelectSites = forwardRef<HTMLDivElement, SitesProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(품번: {code})</Text>
          </Flex>
          {/* <Text fz="xs">{group}</Text> */}
        </Flex>
      </div>
    )
  );

  return (
    <Select
      styles={{
        input: {
          width,
        },
      }}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      value={SitesCode}
      itemComponent={SelectSites}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: SitesProps[], option: SitesProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={SitesInfo({
        Sites: selectedSites as SitesOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewSites({
        SitesName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const SitesInfo = (params: {
  Sites?: SitesOption;
  onChange: (SitesCode: string | null) => void;
}) => {
  const { Sites, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return Sites?.value ? (
    <SitesInfoLabel>
      ({Sites.value})
      <IconX size="1rem" onClick={clearHandler} />
    </SitesInfoLabel>
  ) : null;
};

const AddNewSites = (params: {
  SitesName: string;
  onChange: (SitesCode: string) => void;
}) => {
  const { SitesName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const { mutate } = useSitesMutationQuery("create");

  const onCloseHandler: EventHandler<any> = (values) => {
    mutate({
      sitesGetRequest: {
        name: values.name,
        code: values.code,
      },
    });
    closeModal(values);
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <SiteForm name={SitesName} onSubmit={onCloseHandler} />,
      "",
      "신규 Site 등록"
    ).then((result) => {
      console.log("모달 2에서 전달된 결과값:", result);
      onChange(result?.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 품목({SitesName}) 등록
    </Button>
  );
};
const SitesInfoLabel = styled.div`
  font-size: 8px;
  color: #666666;
  padding-right: 8px;
  display: flex;
  align-items: center;
`;
