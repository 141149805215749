
import { BaseTitle } from "@/test/components/atom/title/BaseTitle"
import { TitleProps } from "@mantine/core"

export const FormDescription = (props: TitleProps) => {

    return (
        <BaseTitle
            size={"1.0rem"} c={"#000000"} weight={400}
            {...props}
        />
    )
}