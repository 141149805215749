import { customFunctions } from "@/config/customFunctions";
import { useModal } from "@/context/ModalStackManager";
import { useWorkDataStore } from "@/store/work.store";
import { FormNumberInput } from "@/test/components/atom/numberInput/form/FormNumberInput";
import { FormTextInput } from "@/test/components/atom/textInput/form/FormTextInput";
import { FormDescription } from "@/test/components/atom/title/form/FormDescription";
import { FormSubTitle } from "@/test/components/atom/title/form/FormSubTitle";
import { BarcodeInput } from "@/test/components/molecule/barcodeInput/BarcodeInput";
import { MantineSelectableTable } from "@/test/components/molecule/mantineSelectableTable/MantineSelectableTable";
import { InventoriesAutoComplete } from "@/test/components/organism/autoComplete/inventory/inventories-autoComplete";
import { LocationAutoComplete } from "@/test/components/organism/autoComplete/location/location-autoComplate";
import FormTemplate from "@/test/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/test/constants/text";
import { useInventoriesFormFetch } from "@/test/fetch/form/useInventoriesFormFetch";
import { useRawMaterialAllHandler } from "@/test/hooks/handler/useRawMaterialAllHandler";
import { customNotification } from "@/utils/notificationShow";
import { setToLocaleString } from "@/utils/unitMark";
import { Flex, Pagination } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  WorksBulkInputPutRequestWorkLotPairsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef, MRT_Row } from "mantine-react-table";
import { useEffect, useMemo } from "react";

const RawMaterialAll = Object.assign(
  {},
  {
    Template: FormTemplate,
    SubTitle: FormSubTitle,
    Description: FormDescription,
    TextInput: FormTextInput,
    NumberInput: FormNumberInput,
    BarcodeInput: BarcodeInput,
    Table: MantineSelectableTable,
    InventoriesAutoComplete: InventoriesAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
    Pagination: Pagination,
  }
);

export const RawMaterialAllForm = () => {
  const { closeModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { work } = workData;
  const { onSubmit } = useRawMaterialAllHandler();

  const form = useForm({
    initialValues: {
      itemCode: "",
      sourceLocationCode: "",
      useBarcode: false,
      barcodeInput: "",
      worksBulkInputPutRequest: [],
    },
  });

  const targetLocationCode = useMemo(() => {
    return (
      work?.equipment?.toLocationCode ??
      work?.locationSetting?.toLocation?.code ??
      work?.routingData?.operation?.toLocationCode ??
      work?.productionPlan?.routingsData?.find(
        (data: any) => data?.code === work?.routingCode
      )?.operation?.toLocationCode
    );
  }, [work]);

  const columnsBoms = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "itemName",
        header: COMMON_TEXT.COLUMN.ITEM_NAME,
      },
      {
        accessorKey: "gradeName",
        header: COMMON_TEXT.COLUMN.GRADE,
      },
      {
        accessorKey: "itemCategoryName",
        header: COMMON_TEXT.COLUMN.COLOR,
      },
      {
        accessorKey: "abstractItemCode",
        header: COMMON_TEXT.COLUMN.SHAPE,
      },
    ],
    []
  );

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "order",
        header: COMMON_TEXT.COLUMN.ORDER,
      },
      {
        accessorKey: "itemName",
        header: COMMON_TEXT.COLUMN.ITEM_NAME,
      },
      {
        accessorKey: "gradeName",
        header: COMMON_TEXT.COLUMN.GRADE,
      },
      {
        accessorKey: "itemCategoryName",
        header: COMMON_TEXT.COLUMN.COLOR,
      },
      {
        accessorKey: "abstractItemCode",
        header: COMMON_TEXT.COLUMN.SHAPE,
      },
      {
        accessorKey: "quantity",
        header: COMMON_TEXT.COLUMN.QUANTITY,
        Cell: ({ cell }) => {
          return (
            <FormNumberInput
              {...form.getInputProps(
                `worksBulkInputPutRequest.${cell.row.index}.quantity`
              )}
              value={Number(cell.getValue())}
            />
          );
        },
      },
    ],
    []
  );

  const bomItemCodes = work?.routingData?.routingBoms?.map(
    (value) => value.itemCode
  );

  const { data } = useInventoriesFormFetch({
    itemCodes: form.values.itemCode ?? bomItemCodes,
    locationCode: form.values.sourceLocationCode,
    lotId: form.values.barcodeInput,
    // enabled: customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING || Boolean(form.values.useBarcode && form.values.barcodeInput),
  });

  const convertedData = useMemo(() => {
    if (!data?.data) return [];
    return data.data.map((row) => ({
      value: row.lotId?.toString() ?? "",
      label: `로트이름: ${row.lot?.name ?? "-"},위치: ${
        row.locationCode
      }, 수량: ${setToLocaleString(row.quantity)}`,
      isDisabled: row?.quantity && Number(row?.quantity) < 0 ? true : false,
    }));
  }, [data?.data]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!form.values.barcodeInput) return;
      if (checkDuplicateLot(form.values.barcodeInput)) return;
      form.setFieldValue("barcodeInput", form.values.barcodeInput);
      form.setFieldValue("useBarcode", true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!inputValue) return;
    if (checkDuplicateLot(inputValue)) return;
    form.setFieldValue("barcodeInput", inputValue);
    form.setFieldValue("useBarcode", true);
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    form.removeListItem("worksBulkInputPutRequest", row.index);
  };

  const checkDuplicateLot = (value: string) => {
    if (
      form.values.worksBulkInputPutRequest.some(
        (data: any) => data.lotId === Number(value)
      )
    ) {
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.DUPLICATE_LOT,
      });
      form.setFieldValue("barcodeInput", "");
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    const request = form.values.worksBulkInputPutRequest.map(
      (data: WorksBulkInputPutRequestWorkLotPairsInner) => ({
        workId: work?.id as number,
        lotId: data.lotId,
        sourceLocationCode: data.sourceLocationCode,
        targetLocationCode: data.targetLocationCode,
        quantity: String(data.quantity),
      })
    );

    onSubmit({ workLotPairs: request });
  };

  const handleInventories = (value: string | null) => {
    if (value === null) return;
    if (!checkDuplicateLot(value)) {
      const { lotId } = data?.data?.filter(
        (data) => data.lotId === Number(value)
      )[0] as ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
      form.setFieldValue("barcodeInput", String(lotId));
      form.setFieldValue("useBarcode", true);
    }
  };

  const routingBoms = work?.routingData?.routingBoms;
  const convertedRoutingBoms = routingBoms?.map((bom) => ({
    itemName: bom.itemWithGrade?.name,
    gradeName: bom.itemWithGrade?.gradeName,
    itemCategoryName: bom.itemWithGrade?.itemCategoryName,
    abstractItemCode: bom.itemWithGrade?.abstractItemCode,
    itemCode: bom.itemCode,
  }));

  useEffect(() => {
    if (!data) return;

    if (form.values.useBarcode && form.values.barcodeInput) {
      const noData = !data?.data?.length;

      if (noData) {
        form.setFieldValue("useBarcode", false);
        form.setFieldValue("barcodeInput", "");
        customNotification.error({
          message: COMMON_TEXT.NOTIFICATION.LOT_NOT_FOUND,
        });
        return;
      }

      const {
        lotId,
        itemCode,
        itemName,
        lot,
        locationCode,
        quantity,
        itemAbstractItemCode,
        grade,
        itemCategory,
        abstractItem,
      } = data
        ?.data[0] as ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
      form.setFieldValue("useBarcode", false);
      form.setFieldValue("barcodeInput", "");
      form.insertListItem("worksBulkInputPutRequest", {
        lotName: lot?.name,
        lotExpiration: lot?.expiration,
        lotId,
        itemCode,
        itemName,
        sourceLocationCode: locationCode,
        targetLocationCode: targetLocationCode,
        itemAbstractItemCode: itemAbstractItemCode,
        gradeName: grade?.name,
        itemCategoryName: itemCategory?.name,
        abstractItemCode: abstractItem?.code,
        quantity,
      });
    }
  }, [form.values.useBarcode, data]);

  return (
    <RawMaterialAll.Template
      w={"100rem"}
      onSubmit={handleOnSubmit}
      onCancel={() => closeModal({})}
      label="일괄 투입"
    >
      <RawMaterialAll.SubTitle>
        {COMMON_TEXT.FORM.RAW_MATERIAL_ALL_SUBTITLE}
      </RawMaterialAll.SubTitle>
      <RawMaterialAll.Description>
        {COMMON_TEXT.FORM.RAW_MATERIAL_ALL_DESCRIPTION}
      </RawMaterialAll.Description>
      <RawMaterialAll.Table
        enableMultiRowSelection={false}
        columns={columnsBoms}
        data={convertedRoutingBoms ?? []}
        onRowSelection={(selectedRows: any) => {
          form.setFieldValue("itemCode", selectedRows[0]?.itemCode);
        }}
      />
      <RawMaterialAll.LocationAutoComplete
        withAsterisk
        label={COMMON_TEXT.LABEL.LOCATION}
        {...form.getInputProps("sourceLocationCode")}
      />
      {customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING && (
        <RawMaterialAll.InventoriesAutoComplete
          disabled={!form.values.sourceLocationCode}
          label={COMMON_TEXT.LABEL.LOT_INFORM}
          value={form.values.barcodeInput}
          data={convertedData ?? []}
          onChange={handleInventories}
        />
      )}
      <Flex justify={"space-between"}>
        <RawMaterialAll.BarcodeInput
          handleBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...form.getInputProps("barcodeInput")}
          label={COMMON_TEXT.LABEL.INPUT_BARCODE_INFORM}
          required
        />
        <Flex gap={"xl"}>
          <RawMaterialAll.TextInput
            withAsterisk
            disabled
            label={COMMON_TEXT.LABEL.TARGET_LOCATION_CODE}
            value={targetLocationCode}
          />
        </Flex>
      </Flex>
      <RawMaterialAll.Table
        enableRowActions={true}
        enableRowSelection={false}
        localization={{
          noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_LOT_INFORM,
        }}
        columns={columns}
        data={form.values.worksBulkInputPutRequest.map(
          (data: WorksBulkInputPutRequestWorkLotPairsInner, index: number) => ({
            order: index + 1,
            ...data,
          })
        )}
        onClickDelete={handleRowDelete}
      />
    </RawMaterialAll.Template>
  );
};
