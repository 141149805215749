import { FormActionButtonGroup } from "@/test/components/molecule/formActionButtonGroup/FormActionButtonGroup";
import { Box, Flex, FlexProps } from "@mantine/core";


interface FormTemplateProps extends FlexProps {
    children?: React.ReactNode;
    onCancel?: () => void;
    onSubmit?: () => void;
    isButtonDisabled?: boolean;
    label?: string;
}

const FormTemplate = ({ children, onCancel, onSubmit, isButtonDisabled, label, ...etcProps }: FormTemplateProps) => (
    <Box>
        <form className="form-content">
            <Flex direction="column" gap='xl' {...etcProps}>
                {children}
                {
                    !isButtonDisabled &&
                    <FormActionButtonGroup
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        label={label}
                    />
                }
            </Flex>
        </form>
    </Box>
);

export default FormTemplate;
