import { useAuthWhoamiGet } from "@/api/useAuthWhoamiGet";
import { BaseButton } from "@/components/base/button/BaseButton";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import FormSelect from "@/test/components/atom/select/form/FormSelect";
import { FormTextInput } from "@/test/components/atom/textInput/form/FormTextInput";
import { EquipmentAutoComplete } from "@/test/components/organism/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/test/components/organism/autoComplete/location/location-autoComplate";
import FormTemplate from "@/test/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/test/constants/text";
import { useUserFormFetch } from "@/test/fetch/form/useUserFormFetch";
import { useUserHandler } from "@/test/hooks/handler/useUserHandler";
import { SelectItem } from "@mantine/core";
import { useForm } from "@mantine/form";

const User = Object.assign(
  {},
  {
    Template: FormTemplate,
    Button: BaseButton,
    TextInput: FormTextInput,
    Select: FormSelect,
    EquipmentAutoComplete: EquipmentAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
  }
);

export const UserUpdate = () => {
  const { closeModal } = useModal();
  const {
    userId,
    userName,
    name,
    equipmentCode,
    setEquipmentCode,
    setWorkId,
    locationCode,
    setLocationCode,
    setPrintCode,
    printCode,
  } = useLocalStorage();

  const data = useUserFormFetch();
  const { onSubmit } = useUserHandler();
  const { refetch } = useAuthWhoamiGet({
    onSuccess: (data) => {},
  });

  const printerData = data.data
    ?.filter(
      (user, index, self) =>
        user?.printerCode !== "" &&
        user?.printerCode !== null &&
        index === self.findIndex((t) => t.printerCode === user.printerCode)
    )
    .map((user) => {
      return {
        value: user?.printerCode as string,
        label: user?.printerCode as string,
      };
    });

  const form = useForm({
    initialValues: {
      id: userId, // pk
      name: name, // 이름
      username: userName, // 아이디
      equipmentCode: equipmentCode, //설비코드
      locationCode: locationCode,
      printCode: printCode,
    },
  });

  const handleSubmit = () => {
    onSubmit({
      masterApiUsersUserIdPutRequest: {
        userId: form.values.id as number,
        usersUserIdDeleteRequest: {
          name: form.values.name,
          username: form.values.username,
          equipmentCode: form.values.equipmentCode,
          locationCode:
            form.values.locationCode === "null"
              ? null
              : form.values.locationCode,
          printerCode: form.values.printCode ?? null,
        },
      },
      successCallback: () => {
        setWorkId(undefined);
        setEquipmentCode(form.values.equipmentCode);
        setLocationCode(form.values.locationCode);
        setPrintCode(form.values.printCode);
        refetch();
        closeModal({});
      },
    });
  };

  return (
    <User.Template onSubmit={handleSubmit} onCancel={() => closeModal({})}>
      <User.TextInput
        label={COMMON_TEXT.LABEL.USER_ID}
        defaultValue={form.values?.username}
        disabled
      />
      <User.TextInput
        label={COMMON_TEXT.LABEL.USER_NAME}
        defaultValue={form.values?.name}
        disabled
      />
      <User.EquipmentAutoComplete
        label={COMMON_TEXT.LABEL.EQUIPMENT}
        defaultValue={form.values.equipmentCode}
        {...form.getInputProps("equipmentCode")}
      />
      <User.LocationAutoComplete
        label={COMMON_TEXT.LABEL.LOCATION}
        defaultValue={form.values.locationCode}
        {...form.getInputProps("locationCode")}
      />
      <User.Select
        label={COMMON_TEXT.LABEL.PRINTER}
        clearable
        defaultValue={form.values.printCode}
        {...form.getInputProps("printCode")}
        data={(printerData as (string | SelectItem)[]) ?? []}
        withinPortal
      />
    </User.Template>
  );
};
